@import 'variables.scss';

// ============
.imageContainer {
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    position: relative;
    width: 100%;
    padding-top: calc(200 / 350 * 100%);
}

.imageContainer ._img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
}

// =============
.imageContainer2 {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
    cursor: pointer;

    &:before {
        content: '';
        display: block;
        padding-top: (210 / 350 * 100%);
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
        object-fit: cover;
        transition: filter 0.3s ease-in-out;
        &:hover{
            filter: brightness(50%);
        }

        &.contain {
            object-fit: contain;
            max-width: 50%;
            max-height: 50%;
        }
    }
}

.dashboard-table-img{
    border-radius: 15px;
    width: 222px;
    height: 110px;
    object-fit: cover;
    transition: filter 0.3s ease-in-out;
  
    &:hover {
      filter: brightness(50%);
    }
}
.smallBtn {
    padding: 6px 10px;
    font-size: 14px;
    border-radius: 5px !important;
}



// ===== custom dropdown =====
.voucherDropdown {
    .searchField .brands {
        background-color: rgba(0, 0, 0, 0.349);
        border-radius: 3px;
        padding: 3px 6px;
        color: #fff;
        margin: 2px 3px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .btnMain {
        color: #000;
        border: 2px solid #ccc;
        border-radius: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        background-color: transparent;

        &:focus,
        &:hover {
            background-color: transparent;
        }

        &::after {
            margin-left: 10px;
            display: none;
        }
    }

    .dropdown-menu {
        border-radius: 0;
        background: linear-gradient(180deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.1) 13%,
                rgba(255, 255, 255, 0.3) 100%);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);

        .dropdownBrandItems {
            padding: 2px 8px;

            .item {
                font-size: 13px;
                color: #fff;
                // ---
                cursor: pointer;
                background-color: #a3a3a3;
                border-radius: 3px;
                padding: 3px 5px;
                margin: 3px 4px;

                // ----
                &:hover {
                    background-color: rgba(0, 0, 0, 0.216);
                }

                &.selected {
                    background-color: #646464;
                }
            }
        }

        .voucherItems {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: red;
            padding: 5px 10px;

            // &:hover {
            //   background-color: #ffffff0c;
            // }
            .item {
                width: 100%;
                background-color: rgba(255, 255, 255, 0.06);
                border-radius: 3px;
                padding: 2px 10px;
            }
        }
    }
}

// ===============
.multipleDropdown {
    width: 100%;

    .customBtn {
        box-sizing: border-box;
        width: 100%;
        height: 50px !important;
        overflow: hidden;
        padding: 15px 20px;
        border-radius: 10px !important;
        background-color: transparent !important;
        border: 2px solid #ccc !important;
        justify-content: start !important;
        font-family: inherit !important;
        font-size: inherit !important;
        line-height: inherit !important;
        color: #8e8e8e !important;

        .selectedItems {
            display: flex;
            gap: 5px;

            span {
                background-color: #a7a7a7 !important;
                color: #fff !important;
                border-radius: 4px;
                padding: 3px 5px;
            }
        }
    }

    .customDrop {
        ul {
            li {
                justify-content: space-between;
                align-items: start !important;
                align-items: start !important;
                justify-content: start !important;
                // white-space: normal; /* Allow text to wrap */
                // word-wrap: break-word; /* Break long words if necessary */
            }
        }
    }
}

// ================
.mask-group-parent.custom {
    .title-here-wrapper {
        .title {
            color: var(--secondary, #333);
            // font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .date {
            color: var(--secondary, #333);
            font-family:'Roboto';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .button-icon {
        padding: 10px;
    }

    .userArea {
        .profileImg {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .createdBy {
            color: var(--secondary, #333);
            // font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.7px;
        }

        .date {
            color: var(--secondary, #333);
            // font-family: Lato;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.6px;
        }
    }
}


// =============
.profileDropdown {
    ._item {
        color: var(--secondary, #333);
        // font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .dropdownWorkspaceArea {
        min-width: 250px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 10px 15px;
        font-size: 20px;

        .titleTop {
            color: var(--secondary, #333);
            // font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .titleBottom {
            color: var(--secondary, #333);
            // font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .workspaceList {
            .profileImg {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            .title {
                color: var(--secondary, #333);
                // font-family: Lato;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .active {
                // color: #d73131;
                color: green;
            }

            .option {
                color: var(--Complementary, #4A90E2);
                // font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }
}


// ===========
.customTab {
    .navLink {
        display: flex;
        align-items: center;
        // color: var(--Complementary, #4A90E2);
        // font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #333333;

        &.active {
            background-color:var(--primary);
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
        }
    }
}


// ============
.modalCustom2 {
    .modal-header {
        color: var(--secondary, #333);
        // font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .modal-body {
        .grayBox {
            border-radius: 10px;
            background: #F2F2F2;
            padding: 15px 20px;
        }

        .upgradePlanArea {
            .title {
                color: var(--secondary, #333);
                // font-family: Roboto;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .desc {
                color: var(--secondary, #333);
                // font-family: Lato;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 8px 0;
            }
        }
    }
}

// =================

.custom-tabs {
    .nav-link {
        color: #000;

        &.active {
            color: #fff;
            background-color: #000;
        }
    }
}

.customTab2 {
    gap: 10px;

    .nav-item {
        .navLink {
            border-radius: 10px 10px 0px 0px;
            background-color: #F2F2F2;
            color: #333;
            // font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 10px 15px;

            &.active {
                background-color: $orangePrimary;
                color: #FFF;
            }
        }
    }
}

.memberTable {
    border-radius: 10px;
    border: 2px solid #F2F2F2;

    thead {
        background-color: var(--primary);
        color: #fff;

        th {
            border: none;
            color: var(--White, #FFF);
            // font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.9px;
            background: $orangePrimary;
            overflow: hidden;

            &:first-child {
                border-radius: 8px 0px 0px 0px;
            }

            &:last-child {
                border-radius: 0px 8px 0px 0px;
            }
        }
    }

    tbody tr {
        td {
            border: none;
            vertical-align: middle;
            padding-top: 8px;
            padding-bottom: 8px;

            .date {
                color: #333;
                // font-family: Lato;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.7px;
            }

            .memberArea {
                .profileImg {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .title {
                    color: var(--secondary, #333);
                    // font-family: Lato;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.7px;
                }

                .title2 {
                    color: var(--secondary, #333);
                    // font-family: Lato;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.6px;
                }
            }
        }
    }

}


// ===============
.dropdownRole {
    .dropdown-toggle {
        outline: transparent;
        border: 0;
        color: #000;

        &:hover,
        &:active {
            background-color: transparent;
        }
    }
}

// =================
.customInviteMemberInputArea {
    background-color: transparent;
    border: 2px solid #ccc;
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    padding: 12px 16px;
    border-radius: 10px;
    min-height: 45px;

    input {
        // border: 0;
        outline: 0;
    }

    select {
        border: 0;
        outline: 0;
    }

    .mList {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        .item {
            background-color: #5c5c5c !important;
            color: #fff !important;
            border-radius: 4px;
            padding: 4px 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .email1 {
                font-size: 15px;
                line-height: normal;
            }

            .role2 {
                font-size: 11px;
                font-weight: 600;
                line-height: normal;
                background-color: #b1b1b1;
                color: #000;
                padding: 1.5px 2.5px;
                border-radius: 2px;
            }

            .btnRemove3 {
                color: #ffffff;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
            }
        }
    }
}

// ===============
.workspaceItem {
    border-radius: 5px;
    background-color: var(--color-darkslategray-100);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    gap: var(--gap-xs);

    .userArea {
        .profileImg {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .title {
            color: var(--secondary, #333);
            // text-align: center;
            // font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .option {
            color: var(--Complementary, #4A90E2);
            // text-align: center;
            // font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}


// ============
.searchInputContainer {
    // margin-left: 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #E5E7EB;
    padding: 0 10px;
    height: 50px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    font-family:'Roboto' !important;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.075px;
    width: 400px;

    // margin: 5px;
    @media (max-width:769px) {
        margin-left: 0px;
        flex: auto;
        width: 100%;
    }

    .searchIcon {
        color: #6C6C6C;
        margin-right: 10px;
    }

    .searchInput {
        border: none;
        outline: none;
        width: 100%;
        padding: 5px 0;
        color: #6C6C6C;
        font-family:'Roboto' !important;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.075px;

        &::placeholder {
            color: #6C6C6C;
        }

        &::-webkit-input-placeholder {
            color: #6C6C6C;
        }

        &:-ms-input-placeholder {
            color: #6C6C6C;
        }

        &::-ms-input-placeholder {
            color: #6C6C6C;
        }

        &:focus {
            box-shadow: none;
        }
    }
}

// ========= add property form ==========
.formBorderBox {
    border-radius: 20px;
    border: 2px solid var(--Gray, #ccc);
    background: var(--White, #fff);
    box-sizing: border-box;
}

.grayBgBox {
    border-radius: 10px;
    background: var(--Accent, #F2F2F2);
}

.whiteBgBox {
    border-radius: 10px;
    background: var(--White, #FFF);
}

.whiteBgBox2 {
    border: 3px solid #F2F2F2;
    border-radius: 0 0 10px 10px;
    background: var(--White, #FFF);
}

._uploadBox {
    height: 200px;
    padding: 10px;
    cursor: pointer;
}

.formStepperSidebar {
    margin: 20px 14px;

    .step {
        margin-left: 14px;
        margin-right: 14px;
        margin-top: 8px;

        ._roundCircle {
            position: relative;

            &::before {
                content: '';
                background-color: #E2DEDE;
                border-radius: 50%;
                height: 24px;
                width: 24px;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &::after {
                content: '';
                background-color: #FFFFFF;
                border-radius: 50%;
                height: 8px;
                width: 8px;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .line {
            width: 2px;
            background-color: #CCCCCC;
        }

        &.active {
            ._roundCircle {
                &::before {
                    background-color: #E03C31;
                }

                &::after {
                    background-color: #FFFFFF;
                }
            }

            .line {
                background-color: #E03C31;
            }
        }
    }

    .content {
        margin-left: 10px;
        text-align: start;

        .title {
            color: var(--secondary, #333);
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .desc {
            color: #858585;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.customInpField {
    border-radius: var(--br-3xs);
    background-color: var(--white);
    border: 2px solid var(--gray);
    box-sizing: border-box;
    // width: 100%;
    overflow: hidden;
    // flex-shrink: 0;
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: flex-start;
    padding: 12px 15px;
    border-radius: 10px;
    border: 2px solid var(--Gray, #ccc);

    color: var(--secondary, #333);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.customInpLabel {
    color: var(--secondary, #333);
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.textMini {
    color: var(--Black, #000);
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


// ===========

.customCheckBox {
    margin-right: 30px;

    input {
        display: none;
        cursor: pointer;

        &:checked {
            + {
                label {
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 5px;
                        left: 10px;
                        width: 6px;
                        height: 14px;
                        border: solid #fff;
                        border-width: 0 3px 3px 0;
                        transform: rotate(45deg);
                    }

                    &:before {
                        background-color: #E03C31;
                        border: 0;
                    }
                }
            }
        }
    }

    label {
        position: relative;
        cursor: pointer;
        color: var(--secondary, #333);
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.7px;

        &:before {
            content: '';
            -webkit-appearance: none;
            background-color: #F2F2F2;
            border: 1px solid #333;
            border-radius: 50%;
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 10px;
            height: 27px;
            width: 27px;
        }
    }
}


// ========== template ============
._templatePage {

    // ======= common =======
    ._templateSlide {
        background-color: var(--white, #FFF) !important;
        min-height: 600px;
        // height: 650px;
        margin-bottom: 50px;
    }

    .templateBg_1 {
        padding: 45px;
        background-image: url('../../app/PropertyTemplate/images/pink-orange-gradient-bg-1.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .templateBg_2 {
        // padding: 45px;
        background-color: #fff;
        background-image: url('../../app/PropertyTemplate/images/sean-whelan-bg-2.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    ._bgOrnage {
        background: var(--Primary, #E03C31);
    }

    ._bgWhite {
        background-color: #fff;
        height: 100%;
    }

    ._bgTransparent {
        background-color: transparent;
        height: 100%;
    }

    ._customFlex {
        display: flex;
        flex-direction: column;
    }

    ._slideTopTitle {
        background: var(--Heading, linear-gradient(90deg, #9D286F 4.67%, #F79B3D 106.09%));
        height: 62px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;
        position: relative;

        &.leftBar {
            background: var(--Primary, #E03C31);

            .h3 {
                margin-left: 28px;
            }

            &::before {
                content: '';
                background: #CCC;
                width: 12px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        .h3 {
            color: var(--White, #FFF);
            font-family: SF Pro Display;
            font-size: 19px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.7px;
            text-transform: uppercase;
            margin-bottom: 0;
            margin-left: 25px; // Default margin-left
            position: relative;
        }
    }



    // ===== separate ======
    .slide_1 {
        .leftArea {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 30px;

            .logo {
                height: 70px;

                img {
                    height: 100%;
                    width: auto;
                }
            }

            .content {
                .h3 {
                    font-family: SF Pro Display;
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    background: var(--Heading, linear-gradient(90deg, #9D286F 4.67%, #F79B3D 106.09%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .p {
                    color: var(--Black, #000);
                    font-family: SF Pro Display;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }

            .userDetail {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .name {
                    color: var(--Black, #000);
                    font-family: SF Pro Display;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 1.8px;
                }

                .mob {
                    color: var(--Black, #000);
                    font-family: SF Pro Display;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }

        .rightArea {}
    }

    .slide_1_b {
        .logoBar {
            min-height: 125px;
            display: flex;
            align-items: center;
            padding: 0 30px;

            ._logo {
                height: 75px;
                display: inline-block;

                img {
                    height: 100%;
                    width: auto;
                }
            }
        }

        .leftArea {
            background: var(--Primary, #E03C31);
            padding: 30px;
            display: flex;
            align-items: center;

            .content {
                .h3 {
                    color: var(--White, #FFF);
                    font-family: SF Pro Display;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .p {
                    color: var(--White, #FFF);
                    font-family: SF Pro Display;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: normal;
                }
            }
        }

        .rightArea {
            .imgDiv {
                overflow: hidden;
                background-color: rgb(255, 255, 255);
                position: relative;
                width: 100%;

                &_imgContainer {
                    position: relative;
                    width: 100%;
                    padding-top: calc(60 / 100 * 100%);
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    display: block;
                }
            }
        }

        .userDetail {
            display: flex;
            align-items: center;
            gap: 2px;
            padding: 0 30px;
            height: 100%;
            min-height: 70px;

            .name {
                color: var(--Primary, #E03C31);
                font-family: SF Pro Display;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 1.8px;
                margin-right: 8px;
            }

            .mob {
                color: var(--Black, #000);
                font-family: SF Pro Display;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                margin-right: 8px;
            }
        }
    }

    .slide_2 {

        ._bgWhite,
        ._bgTransparent {
            padding: 30px;

            .contentArea {
                .p {
                    color: var(--Black, #000);
                    font-family: SF Pro Display;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 1.5px;
                }
            }
        }
    }

    .slide_3 {

        ._bgWhite,
        ._bgTransparent {
            padding: 30px;

            .contentArea {
                height: 100%;

                .leftArea {
                    .imgDiv {
                        overflow: hidden;
                        background-color: rgb(255, 255, 255);
                        position: relative;
                        width: 100%;

                        &:first-child {
                            margin-bottom: 20px;
                        }

                        &_imgContainer {
                            position: relative;
                            width: 100%;
                            padding-top: calc(185 / 350 * 100%);
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            display: block;
                        }
                    }
                }

                .leftArea_roundImg {
                    .containerDiv {
                        position: relative;
                        height: 100%;
                      
                        .imgDiv1,
                        .imgDiv2 {
                          position: absolute;
                          border-radius: 50%;
                          overflow: hidden;
                      
                          ._img {
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            height: 100%;
                            width: 100%;
                          }
                        }
                      
                        .imgDiv1 {
                          top: 8%;
                          right: 8%;
                          height: 180px;
                          width: 180px;
                          border: 5px solid #e03c31;
                        }
                      
                        .imgDiv2 {
                          bottom: 18%;
                          height: 300px;
                          width: 300px;
                          border: 20px solid #e03c31;
                        }
                      }
                      
                }


                .rightArea {
                    .iconsDiv {
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .icon {
                            height: 40px;
                            width: 40px;
                            margin-bottom: 3px;

                            svg {
                                height: 100%;
                                width: 100%;
                            }
                        }

                        .title1 {
                            color: var(--secondary, #333);
                            text-align: center;
                            font-family: SF Pro Display;
                            font-size: 19px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }

                        .title2 {
                            color: var(--secondary, #333);
                            font-family: SF Pro Display;
                            font-size: 19px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }

    // slide_4, slide_5 
    .slide_4 {

        ._bgWhite,
        ._bgTransparent {
            padding: 30px;

            .contentArea {
                .leftArea {
                    .imgDiv {
                        overflow: hidden;
                        background-color: rgb(255, 255, 255);
                        position: relative;
                        width: 100%;
        
                        &_imgContainer {
                            position: relative;
                            width: 100%;
                            padding-top: calc(100 / 90 * 100%);
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            display: block;
                        }
                    }
                }

                .rightArea {
                    .title {
                        color: var(--Black, #000);
                        font-family: SF Pro Display;
                        font-size: 50px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 4px;
                    }

                    .title2 {
                        color: var(--Black, #000);
                        font-family: SF Pro Display;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 2.5px;

                        margin-bottom: 18px;
                    }

                    .desc {
                        color: var(--Black, #000);
                        font-family: SF Pro Display;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 1.5px;
                    }
                }
            }
        }
    }

    // slide_6, slide_7,slide_8 
    .slide_6 {

        ._bgWhite,
        ._bgTransparent {
            padding: 30px;

            .cardDiv {
                border: 1px solid var(--Gray, #CCC);
                background: var(--White, #FFF);
                min-height: 406px;
                
                .imgDiv {
                    overflow: hidden;
                    background-color: rgb(255, 255, 255);
                    position: relative;
                    width: 100%;
    
                    &_imgContainer {
                        position: relative;
                        width: 100%;
                        padding-top: calc(85 / 100 * 100%);
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        display: block;
                    }
                }

                .cardContent {
                    min-height: 200px;
                    padding: 18px 10px;

                    .title {
                        color: var(--secondary, #333);
                        font-family: SF Pro Display;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 1.2px;
                        margin-bottom: 12px;
                    }

                    .address {
                        color: var(--secondary, #333);
                        font-family: SF Pro Display;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.9px;

                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}