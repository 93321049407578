
@import 'variables.scss';

body {
  font-family:'Roboto';
}
.font40White {
  color: var(--white);
  text-align: center;
  font-family:'Roboto';
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.font30Blue {
  color: var(--secondary);
  font-family:'Roboto';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.font30White {
  color: var(--white);
  text-align: center;
  font-family:'Roboto';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.font30Black {
  color: var(--black);
  text-align: center;
  font-family:'Roboto';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 100px;
    display: flex;
    align-items: center;
}
.font24Black {
  color: var(--black);
  font-family:'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.font24Blue {
  color: var(--secondary);
  font-family:'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.font20White {
  color: var(--white);
  font-family:'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.font24White {
  color: var(--white);
  font-family:'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.font20Blk {
  color: var(--black);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.font20BlkReg{
  color: var(--black);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.font16Blk {
  color: var(--black);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.link20White {
  color: var(--white);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  font-family:'Roboto';
  &:hover {
    color: var(--secondaryRed);
    text-decoration: none;
  }
}

.btnPrimary {
  display: inline-flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 30px !important;
  background: var(--secondary);
  color: var(--White, #fff);
  gap: 10px;
  text-align: center;
  font-family:'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  text-decoration: none;
  border: 0;
  &:hover,
  &:active,
  &:disabled {
    background-color: var(--secondary) !important;
    color: var(--White, #fff) !important;
  }
}
.btnBordered {
  display: inline-flex;
  padding: 15px 39px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--white);
  color: $primary;
  text-align: center;
  font-family:'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  border: solid 1px var(--primary);
  &:hover {
    border: solid 1px var(--secondary);
  }
}
.btnGreen {
  display: inline-flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
background: linear-gradient(90deg, rgba(239,52,42,1) 0%, rgba(250,166,59,1) 100%);
  font-family:'Roboto';
  color: var(--white);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover, &:focus, &:active, &:disabled {
    background: rgb(239,52,42);
  background: linear-gradient(90deg, rgba(239,52,42,1) 0%, rgba(250,166,59,1) 80%);
  }
  &.sm{
    padding: 8px 25px !important;
  }
}

.btnGreen:disabled {
  cursor: not-allowed !important;
}

.btnNewPrimary {
  display: inline-flex;
  padding: 15px 30px;
  background: $orangePrimary;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  color: var(--white);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  cursor: pointer;
  &:hover, &:focus, &:active, &:disabled {
  background: rgb(185, 81, 43);
  color: var(--white);
  }
  &.sm{
    padding: 10px 25px !important;
  }
}

.btnBordered-orange {
  display: inline-flex;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: $white;
  color: $gray;
  text-align: center;
  font-family:'Roboto';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  border: solid 1px $gray;
  &.active {
    border: solid 1px $orangePrimary;
    color: $orangePrimary;
  }
}

.btnNewSecondary {
  display: inline-flex;
  padding: 10px 22px;
  background: rgba($orangePrimary, 0.1);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  color: $orangePrimary;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid $orangePrimary;
  cursor: pointer;
  &:hover, &:focus, &:active, &:disabled {
    background: rgba($orangePrimary, 0.2);
    color: $orangePrimary;
    border: 1px solid $orangePrimary;
  }
  &.sm{
    padding: 10px 25px !important;
  }
}


.pricing {
  position: relative;
  background-color: var(--white);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: var(--font-size-11xl);
  color: var(--secondary);
  font-family:'Roboto';
  .logo-child,
  .pricing-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 1750px;
    height: 1159px;
  }
  .logo-child {
    border-radius: 50%;
    background-color: var(--primary);
    width: 24px;
    height: 24px;
  }
  .logo-here {
    position: absolute;
    top: 2px;
    left: 34px;
    font-weight: 500;
  }
  .logo {
    width: 113px;
    height: 24px;
  }
  .home,
  .logo {
    position: relative;
  }
  .home a {
    color: var(--Black);
    font-weight: 700;
  }
  .navlink {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs);
  }
  .login {
    position: relative;
    letter-spacing: 0.05em;
    font-weight: 600;
    border: none;
    background: none;
    cursor: pointer;
  }
  .vector-icon {
    position: relative;
    width: 19px;
    height: 12px;
    display: none;
  }
  .button-icon,
  .login-parent {
    display: flex;
    justify-content: flex-start;
  }
  .login-parent {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-6xs);
  }
  .button-icon {
    border-radius: var(--br-11xl);
    background-color: var(--primary);
    overflow: hidden;
    flex-direction: column;

    align-items: center;
    padding: var(--padding-mini) var(--padding-7xl);
    text-align: center;
    color: var(--white);
    min-width: 120px;
  }
  .heading-1-wrapper {
    top: 259px;
    left: calc(50% - 203px);
    font-size: var(--font-size-17xl);
  }
  .subheading {
    // position: absolute;
    // top: 320px;
    // left: calc(50% - 285px);
    font-size: var(--font-size-xl);
    color: var(--black);
  }
  .subheading,
  .subheading1 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  .group-child,
  .group-item {
    position: absolute;
    border-radius: var(--br-11xl);
  }
  .group-child {
    top: -2px;
    left: -2px;
    background-color: #eaf4ff;
    border: 2px solid var(--primary);
    box-sizing: border-box;
    width: 76px;
    height: 40px;
  }
  .group-item {
    top: 3px;
    left: 39px;
    background-color: var(--color-limegreen);
    width: 30px;
    height: 30px;
  }
  .rectangle-parent {
    position: relative;
    width: 72px;
    height: 36px;
  }
  .subheading-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 14px;
    font-size: var(--font-size-xl);
    color: var(--black);
    .toggle-switch-container {
      align-content: center;
    }

    .toggle-switch {
      margin: 0;
    }

    .toggle-switch {
      width: 10px;
      position: relative;
    }

    .toggle-switch input {
      // position: absolute;
      // top: 0;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }

    .toggle-switch input:checked {
      z-index: 1;
    }

    .toggle-switch input:checked + label {
      opacity: 1;
      cursor: default;
    }

    .toggle-switch input:not(:checked) + label:hover {
      opacity: 0.5;
    }

    .toggle-switch .toggle-outside {
      height: 100%;
      border-radius: 17px;
      padding: 11px;
      overflow: hidden;
      transition: opacity 0.3s ease-in-out;
    }

    .toggle-switch .toggle-inside {
      border-radius: 30px;
      background: #424242;
      position: absolute;
      transition: all 0.3s ease-in-out;
    }

    .switch-vertical {
      width: auto;
      height: 70px;
      margin-left: 27px;
    }

    .switch-vertical input {
      // height: 100%;
      // width: 60px;
      right: 0;
      margin: 0;
    }

    .toggle-switch label {
      color: var(--black);
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
    }

    .switch-vertical label {
      font-size: 20px;
      // line-height: 25px;
      font-weight: 400;
      width: auto;
      top: 6px;
      margin: 0;
      text-align: left;
      // padding-left: 12px;
    }

    .toggle-switch span.normal {
      color: #53575a;
      font-size: 16px;
      font-weight: 600;
      padding-left: 12px;
    }

    .switch-vertical .toggle-outside {
      top: -2px;
      // left: -2px;
      // right: 80px;
      background-color: #eaf4ff;
      border: 2px solid var(--primary);
      box-sizing: border-box;
      width: 72px;
      height: 36px;
      position: relative;
      right: 0;
    }

    .switch-vertical .toggle-inside {
      top: 1px;
      background-color: var(--color-limegreen);
      width: 30px;
      height: 30px;
    }

    .switch-vertical input:checked ~ .toggle-outside .toggle-inside {
      top: 1px;
      left: 2px;
    }

    .switch-vertical input ~ input:checked ~ .toggle-outside .toggle-inside {
      top: 1px;
      right: 0px;
    }
  }
  .heading-2 {
    position: relative;
    font-weight: 600;
    color:#6C6C6C;
    font-size: 20px;
    text-align: start;
  }
  .plan-text {
    color: var(--secondary);
    font-weight: 700;
  }
  .heading-2-container,
  .heading-2-wrapper,
  .month-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .month-wrapper {
    padding: var(--padding-3xs) 0;
    text-align: left;
    color: var(--gray);
    font-size: 22px;
    font-weight: bold;
    background: #fff;
    border-radius: 100%;
    width: 160px;
    height: 160px;
  }
  .property-description {
    margin-bottom: 0;
    // margin-left: 15px;
  }
  .property-description-1-user-lo {
    margin: 0;
    padding-left: var(--padding-8xl);
  }
  .property-description-1-container {
    position: relative;
    line-height: 30px;
  }
  .button-icon1,
  .subheading3 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .subheading3 {
    padding: 0px 18px;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    font-size: var(--font-inherit);
    font-family:'Roboto';
  }
  .button-icon1 {
    border-radius: var(--br-11xl);
    background-color: var(--gray);
    flex-direction: column;
    padding: var(--padding-mini) var(--padding-7xl);
    font-size: var(--font-size-base);
    color: var(--primary);
  }
  .instance-parent {
    position: relative;
    border-radius: 30px;
    background-color: $white;
    // backdrop-filter: blur(4px);
    width: 100%;
    flex-shrink: 0;
    display: flex;
    box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.25);
    color:#6C6C6C;
    font-size: 12px !important;
    font-weight: 500;
    // height: 400px;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
  }
  .heading-2-frame,
  .month-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .month-container {
    top: 53px;
    left: calc(50% - 53px);
    padding: var(--padding-3xs) 0;
    color: var(--gray);
  }
  .heading-2-frame {
    top: 24px;
    left: calc(50% - 65px);
    text-align: center;
    font-size: var(--font-size-11xl);
  }
  .frame-group {
    position: relative;
    border-radius: var(--br-xl);
    background-color: rgba(9, 50, 84, 0.8);
    backdrop-filter: blur(4px);
    width: 350px;
    height: 404px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: left;
    font-size: var(--font-size-xl);
  }
  .frame-container {
    position: relative;
    border-radius: var(--br-xl);
    background-color: rgba(9, 50, 84, 0.7);
    backdrop-filter: blur(4px);
    width: 350px;
    height: 404px;
    // overflow: hidden;
    flex-shrink: 0;
    text-align: left;
    font-size: var(--font-size-xl);
  }
  .heading-2-wrapper1 {
    position: absolute;
    top: 24px;
    left: calc(50% - 87px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .need-additional-seats {
    position: relative;
    line-height: 30px;
    display: inline-block;
    width: 282px;
    flex-shrink: 0;
  }
  .button-icon4,
  .subheading6 {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .subheading6 {
    top: 119px;
    left: calc(50% - 141px);
    flex-direction: row;
    flex-wrap: wrap;
    font-size: var(--font-size-xl);
  }
  .button-icon4 {
    border-radius: var(--br-11xl);
    background-color: var(--gray);
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-mini) var(--padding-7xl);
    font-size: var(--font-size-base);
    color: var(--primary);
    position: absolute;
    bottom: 50px;
  }
  .instance-group {
    position: relative;
    border-radius: var(--br-xl);
    background-color: var(--primary);
    backdrop-filter: blur(4px);
    width: 350px;
    height: 404px;
    // overflow: hidden;
    flex-shrink: 0;
  }
  .frame-parent,
  .heading-2-wrapper2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .frame-parent {
    display: flex;
    flex-direction: row;
    gap: var(--gap-base);
    text-align: center;
    color: var(--white);
    gap: 23px;
  }
  .heading-2-wrapper2 {
    align-items: center;
  }
  .bannerimg-1-icon {
    position: relative;
    width: 162px;
    height: 151px;
    object-fit: cover;
  }
  .instance-container {
    background-color: var(--primary);
    // overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 84px;
    box-sizing: border-box;
    gap: 1007px;
    color: var(--white);
  }
  .pricing-item {
    border-radius: var(--br-11xl);
    background-color: var(--color-limegreen);
    width: 92px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }
  .save-25 {
    font-size: 14px;
    color: var(--white);
  }
}
.modal-payment {
  .modal-dialog {
    justify-content: center;

    .modal-content {
      // max-width: 420px;
      background-color: #c4cfda;
      border-radius: 0;
      // min-height: 70vh;

      .modal-header {
        padding: 0;

        .btn-close {
          width: 36px;
          height: 36px;
          border-radius: 0;
          color: #c4cfda;
          background-color: #53575a;
          padding: 0;
          margin: 0;
          margin-left: auto;
        }
      }
    }
  }
}

.font14GrayModal {
  .inpPromoWrapper {
    padding: 5px 2px;
    background-color: #fff;

    .ic {
      margin: 5px 12px;

      svg {
        color: #101820;
        height: 25px;
        width: auto;
      }
    }

    label {
      font-size: 12px;
      line-height: normal;
    }

    input {
      border: none;
      outline: none;
      color: #101820;
      font-family:'Roboto';
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      background-color: #fff;
      border-radius: 0;
      padding: 0;

      &::placeholder {
        color: #c5d0db;
      }
    }
  }

  .btnYellowGradnt {
    background: linear-gradient(180deg, #fff 0%, #ffe000 100%);
    border: none;
    outline: none;
    padding: 10px 15px;
    color: #101820;
    font-family:'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  width: 100%;
  @media (max-width: 1400px) {
    width: 100%;
  }
  .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid #000;
    border-top-color: #000;
    animation: 1.5s spin infinite linear;
  }
}

:host-context(.sidebar-icon-only) {
  .spinner-wrapper {
    width: 100%;
  }
}

:host-context(.sidebar-mini) {
  .spinner-wrapper {
    width: 100%;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
$dots: 5;

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9999;
  .dot {
    width: 20px;
    height: 20px;
    background-color: #ffce06;
    border-radius: 50%;
    margin: 0 5px;
    animation: bounce 1.1s ease infinite;

    @for $i from 1 through $dots {
      &:nth-child(#{$i}) {
        animation-delay: 0.1s * ($i - 1);
      }
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-60px);
  }
  60% {
    transform: translateY(25px);
  }
}

.header {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  // background-color: var(--white);
}
.header,
.heading-1-wrapper,
.navlink-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navlink-parent {
  flex: 1;
  justify-content: flex-end;
  gap: 18px;
  color: var(--black);
  font-family:'Roboto';
}
.header .heading-1-wrapper {
  position: absolute;
  justify-content: center;
  text-align: left;
}

.nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toast:not(.show) {
  display: block;
}

.toast-container {
  max-width: 500px;
  background-color: var(--gray);
  margin: 10px;
  z-index: 999 !important;
  position: fixed;
  border: 1px solid var(--primary);
}

// .btn-close {
//   display: none;
//   box-sizing: content-box;
//   width: 1em;
//   height: 1em;
//   padding: 0.25em 0.25em;
//   color: var(--bs-btn-close-color);
//   background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
//   border: 0;
//   border-radius: 0.375rem;
// }

.btnDanger {
  display: inline-flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: red;
  color: var(--White, #fff);
  text-align: center;
  font-family:'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  border: 0;
  cursor: pointer;
}
.phone-input-container {
  position: relative;
}

.country-flag {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px; /* Adjust the left position as needed */
  /* Add additional styling as needed */
}

/* Style the flag image */
.country-flag img {
  width: 24px; /* Adjust the width as needed */
  height: auto;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  display: none !important;
}

.search-top-main {
  background-color: var(--gray);
  width: 100%;
  // top: 100%;
  // borderRadius: '4px',
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 4px 11px;
  margin-bottom: 8px;
  margin-top: 10px;
  position: absolute;
  z-index: 999;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  // height: 3.5rem
}
.planPrice {
  font-size: 40px;
  line-height: 24px;
  font-weight: 500;
  color: $orangePrimary;
  span {
    font-size: 16px;
    font-weight: 400;
  }
}
.checkboxBG {
  // background-color: var(--primary);
  color: var(--secondary);
  font-size: 18px;
  border-radius: 16px 16px 0 0;
  .checkboxPart {
    margin-right: 20px;
    display: flex;
  }
}
.checkboxNewBG {
  color: #fff;
  font-size: 18px;
  border-radius: 16px 16px 0 0;
  margin: 10px 0 50px 0;
  .checkboxPart {
    margin: 0 20px;
    display: flex;
  }
}
.borderTop {
  border-top: solid 2px var(--primary);
}
.input-field25 {
  background-color: var(--white);
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-xl);
  border-radius: 5px;
  border: 1px solid #E5E7EB;
}
.PhoneInputInput {
  border: 0;
}

.btnSecondary {
  height: 100%;
  display: inline-flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--secondary);
  color: var(--White, #fff);
  text-align: center;
  font-family:'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  border: 0;
  cursor: pointer;
  text-decoration: none;
}

.generation-modal {
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    max-width: 450px;
    // height: 300px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--text-white, #fff);
  }
}
.generation-text {
  color: var(--secondary, #333);
  text-align: center;
  font-family:'Roboto';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.audio-button {
  border-radius: 5px;
  background: rgba($orangePrimary, 0.1);
  width: 44px;
  height: 44px;
  padding: 10px;
  border: none;
}
// ============
.imageContainer {
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  position: relative;
  width: 100%;
  // padding-top: calc(200 / 350 * 100%);
  aspect-ratio: 1/1;
}

.imageContainer ._img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.editBtn {
  border: none;
  border-radius: 30px;
  padding: 8px;
  background: var(--white);
  font-size: 16px;
  color: var(--black);
  padding: 10px 26px;
}

.w-90 {
  width: 90% !important;
}
.ppt {
  background: rgba($primary, 1);
}

.shareContent {
  @media (max-width: "767px") {
    flex-direction: column;
    align-items: start !important;
  }
}



/* PropertyLinkForm.css */
.property-link-form {
  max-width: 760px;
  margin: 0 auto;
}

.form-section {
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  label{
    margin-top: 4px;
  }
  .form-label {
    text-transform: capitalize;
    font-weight: 600;
    color: $secondary;
    font-size: 40px;
    margin-bottom: 0.5rem;
    display: block;
  }
  .form-label-20{
    text-transform: capitalize;
    font-weight: 600;
    color: $secondary;
    font-size: 20px;
  }
  .form-check-input:checked{
    background-color: #EB8642 ;
    border-color: #E14433; 
  }
}


.platform-options {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.platform-radio {
  cursor: pointer;
  color: $secondary;
  font-size: 20px;
  font-weight: 500;
}

.platform-radio input[type="radio"] {
  cursor: pointer;
}

.form-control {
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  background-color: transparent;
  padding: 0.75rem;
  font-size: 1rem;
  color:rgb(40, 38, 38) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &::placeholder {
    color: #716f6f;
    opacity: 1; 
  }

  &:focus::placeholder {
    color: #d2cfcf; // Optional: Change color on focus
  }
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-color: transparent !important;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.submit-button {
  background: linear-gradient(90deg, rgba(239,52,42,1) 0%, rgba(250,166,59,1) 100%);
  border: none;
  padding: 0.75rem 2rem;
  font-weight: 600;
  border-radius: 30px;
  width: 100%;
  margin-top: 1rem;
  transition: background 0.3s ease;
}

.submit-button:hover:not(:disabled) {
  background: linear-gradient(90deg, rgba(239,52,42,1) 0%, rgba(250,166,59,1) 80%);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}