@import 'variables.scss';

.ImageEditorcard {
    padding: 28px;
    width: 100%;
    height: 80%;
    background-color: #fff;
    .card_body {
        display: flex;
        justify-content: center;
        .imageCrop-sidebar {
            span {
                font-size: 15px;
                color: #404040;
                margin-bottom: 4px;
                display: block;
            }
            .side_body {
                width: 250px;
                border: 1px solid #c3c5c4;
                margin-right: 8px;
                height: 370px;
                padding: 10px;
                .filter_key {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 8px;
                    button.active {
                        background-color: $orangePrimary;
                        color: #fff;
                    }
                    button {
                        padding: 8px;
                        background-repeat: 3px;
                        border: 1px solid #dad7d7;
                        cursor: pointer;
                        color: #404040;
                        text-transform: capitalize;
                    }
                }
                .filter_slider {
                    margin: 15px 0px;
                    .label_bar {
                        margin-bottom: 4px;
                        display: flex;
                        justify-content: space-between;
                        label,
                        span {
                            color: #404040;
                            font-size: 15px;
                            text-transform: capitalize;
                        }
                    }
                    input[type="range"] {
                        /* removing default appearance */
                        -webkit-appearance: none;
                        appearance: none; 
                        /* creating a custom design */
                        width: 100%;
                        cursor: pointer;
                        outline: none;
                        /*  slider progress trick  */
                        overflow: hidden;
                        border-radius: 16px;
                      }
                      
                      /* Track: webkit browsers */
                      input[type="range"]::-webkit-slider-runnable-track {
                        height: 15px;
                        background: #ccc;
                        border-radius: 16px;
                      }
                      
                      /* Track: Mozilla Firefox */
                      input[type="range"]::-moz-range-track {
                        height: 15px;
                        background: #ccc;
                        border-radius: 16px;
                      }
                      
                      /* Thumb: webkit */
                      input[type="range"]::-webkit-slider-thumb {
                        /* removing default appearance */
                        -webkit-appearance: none;
                        appearance: none; 
                        /* creating a custom design */
                        height: 15px;
                        width: 15px;
                        background-color: #fff;
                        border-radius: 50%;
                        border: 2px solid $orangePrimary;
                        /*  slider progress trick  */
                        box-shadow: -407px 0 0 400px $orangePrimary;
                      }
                      
                      
                      /* Thumb: Firefox */
                      input[type="range"]::-moz-range-thumb {
                        height: 15px;
                        width: 15px;
                        background-color: #fff;
                        border-radius: 50%;
                        border: 1px solid $orangePrimary;
                        /*  slider progress trick  */
                        box-shadow: -407px 0 0 400px $orangePrimary;
                      }
                }
            
                
                .rotate {
                    label {
                        margin-bottom: 8px;
                        display: block;
                        font-size: 15px;
                        color: #404040;
                    }
                    .icon {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-gap: 5px;
                        div {
                            border: 1px solid rgb(179, 194, 194);
                            padding: 8px 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }
                }
            }
            .image_select {
                margin: 20px 0px;
                display: flex;
                justify-content: center;
                input {
                    display: none;
                }
                .cancelBtn{
                    padding: 8px 20px;
                    border-radius: 30px;
                    background: none;
                    color: var(--black);
                    font-family:'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.8px;
                    border: 0;
                    border: 1px solid $primary;
                }
                .redo,
                .undo {
                    background-color: #000000;
                    margin-left: 5px;
                    color: #fff;
                    padding: 5px 10px;
                    font-size: 18px;
                }
                .SelectImageBtn {
                    margin-left: 5px;
                    padding: 10px 20px;
                    border-radius: 5px;
                    background: $orangePrimary;
                    color: white;
                    font-family:'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.8px;
                    border: 0;
                }
                .crop {
                    background-color: #8e33b7;
                    margin-left: 5px;
                }
            }
        }
        .image_section {
            margin-left: 8px;
            width: 380px;
            background-color: #f0f0f0;
            height: 370px;
            .image {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                img {
                    object-fit: scale-down;
                    transition: all 0.5s;
                    width: 100%;
                    height: 100%;
                }
                label {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    border: dotted;
                    border-color: #c5c9c5;
                    cursor: pointer;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    svg {
                        font-size: 40px;
                        color: #b4acac;
                    }
                    span {
                        color: #404040;
                    }
                }
            }
            .reset {
                margin-top: 20px;
                display: flex;
                justify-content: end;
                .saveBtn{
                    margin-left: 10px;
                    padding: 10px 20px;
                    border-radius: 5px;
                    background: $orangePrimary;
                    color: white;
                    font-family:'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.8px;
                    border: 0;
                }
                .resetBtn{
                    padding: 10px 20px;
                    border-radius: 5px;
                    background:$primary;
                    color: white;
                    font-family:'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.8px;
                    border: 0;
                    &:hover {
                    //   border: solid 1px var(--secondary);
                    }
                }
                // button {
                //     background-color: rgb(242, 53, 85);
                //     padding: 8px;
                //     border-radius: 3px;
                //     outline: none;
                //     border: 1px solid #dad7d7;
                //     cursor: pointer;
                //     color: #fff;
                //     font-weight: 600;
                //     text-transform: capitalize;
                //     font-size: 15px;
                // }
            }
        }
        @media (max-width: 468px) {
            flex-direction: column-reverse;
        }
    }
}


.imageCount {
    background-color: rgba($color: #000000, $alpha: .6);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 700;
}

@media (max-width: 768px) {
    .ImageEditorcard {
        padding: 20px;
    }

    .image_section {
        width: 100%;
    }

    /* Adjust font sizes and button sizes as needed */
}

@media (max-width: 480px) {
    .card_body {
        flex-direction: column-reverse;
    }

    .imageCrop-sidebar, .image_section {
        width: 100%;
    }

    .imageCrop-sidebar {
        margin-top: 10px;
    }

    /* Adjust font sizes, button sizes, and other elements for mobile */
}
