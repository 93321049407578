@import 'variables.scss';

.home {
  position: relative;
  font-size: 22px;
  font-weight: 600 !important;
}

.mask-group-icon {
  position: relative;
  // width: 410px;
  // height: 230px;
  // object-fit: cover;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.title-here-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.frame-wrapper-generation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  word-break: break-all;
}

.login {
  position: relative;
  letter-spacing: 0.05em;
  font-weight: 600;
  border: none;
  background: none;
}

.vector-icon {
  position: relative;
  width: 19px;
  height: 12px;
  display: none;
}

.login-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}

.button-icon,
.frame-parent-generation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.button-icon {
  border-radius: var(--br-11xl);
  background-color: var(--primary);
  overflow: hidden;
  padding: var(--padding-mini) var(--padding-7xl);
  text-align: center;
  color: var(--white);
}

.frame-parent-generation {
  padding: 0 var(--padding-3xs);
  gap: var(--gap-3xs);
}

.mask-group-parent {
  border-radius: 15px;
  // background-color: var(--color-gainsboro);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-xl);
  gap: var(--gap-xs);
  // height: 27%;
  // cursor: pointer;
  height: 100%;
}

.dashboard {
  // display: flex;
  // justify-content: center;
  gap: 20px;
  margin-top: 25px;
  flex-wrap: wrap;
  position: relative;
  background-color: var(--white);
  width: 100%;
  // height: 1380px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--black);
  font-family: var(--font-AlumniSans);
}

.generate-property {
  display: flex;
  padding: 15px 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 30px;
  background: var(--secondary);
  color: var(--White, #fff);
  text-align: center;
  font-family: var(--font-AlumniSans);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  border: none;
  text-decoration: none;
  cursor: pointer;
}

.imgSize {
  // height: 230px;
  // width: 100%;
  aspect-ratio: 1/1;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.generate_header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;

  .generate_header_filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;

    .icon-bg {
      background: rgba($orangePrimary, 0.1);
      width: 45px;
      height: 45px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center,
    }
  }

  @media (max-width: 769px) {
    justify-content: center;

    .btnSecondary {
      font-size: 15px;
      padding: 10px 25px;
    }

    .customTab {
      width: 100%;

      .NavItems1 {
        width: 30%;

        .NavItem_text {
          font-size: 15px;
        }
      }

      .NavItems2 {
        width: 70%;

        .NavItem_text {
          font-size: 15px;
        }
      }
    }
  }
}


/* Sidebar.css */
.sidebar {
  min-width: 260px;
  width: 260px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.sidebar.collapsed {
  min-width: 100px;
  width: 100px;
}

.sidebar.collapsed .menu-title,
.sidebar.collapsed .profile-title,
.sidebar.collapsed .profile-email,
.sidebar.collapsed .sub-items,
.sidebar.collapsed .logo-text {
  display: none !important;
}

.sidebar-header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  // border-bottom: 1px solid #eee;
}

.logo-container {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.toggle-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-btn:hover {
  color: #393E41;
}

.menu-items {
  padding: 15px 0;
  overflow-y: auto;
  flex-grow: 1;
}

.menu-item {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $secondary;
  transition: all 0.3s ease;
  white-space: nowrap;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 14px;
}

.menu-item:hover {
  background: rgba(108, 108, 108, 0.10);
  color: $secondary;
  border-radius: 5px;
}

.menu-item.active {
  background: rgba(108, 108, 108, 0.10);
  color: $secondary;
  border-radius: 5px;
  // border-left: 3px solid $secondary;
}

.icon-container {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.menu-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-title {
  color: $primary;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0;
}

.profile-email {
  color: rgba($primary, 0.75);
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.badge {
  background: #CCFBF1;
  color: #115E59;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 4px;
  margin-left: 10px;
}

.sub-items {
  padding-left: 59px;
  background: #f8f9fa;
}

.sub-item {
  padding: 8px 0;
  color: #393E41;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sub-item:hover {
  color: #393E41;
}

.main-content {
  margin-left: 265px;
  padding: 20px;
  border-radius: 30px;
  background: #FFF;
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  transition: all 0.3s ease;
  width: 100%;
}

.sidebar.collapsed+.main-content {
  margin-left: 100px;
}

.content-header {
  background: linear-gradient(45deg, #ff5722, #ff7043);
  margin: -20px -20px 20px -20px;
  padding: 30px;
  color: white;
  border-radius: 0 0 10px 10px;
}

.content-header h1 {
  margin: 0;
  font-size: 2rem;
}

/* Content Sections Styling */
.content-section {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quick-actions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.action-card {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-card:hover {
  background: #ff57221a;
  color: #ff5722;
}

.stats-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.stat-card {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.template-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.template-card {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.workspace-list {
  margin-top: 20px;
}

.workspace-item {
  padding: 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: all 0.3s ease;
}


.custom-sidebar {
  .user {
    display: flex;
    width: 100%;

    .profile-box {
      display: flex;
      width: 45px;
      height: 45px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 50%;
      background: rgba(255, 107, 53, 0.10);
      margin: 0 20px 0 0;
      color: $orangePrimary;
      text-align: center;
      font-family: 'Roboto';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.08px;
    }
  }
}

.sidebar.collapsed .custom-sidebar .user h3 {
  display: none;
}

.create-modal {
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    padding-top: 20px;
  }

  .modal-title {
    color: $secondary;
    text-align: center;
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 600;
  }

  .model-pera {
    color: $secondary;
    font-size: 18px;
    font-weight: 500;
  }

  .model-or {
    color: $secondary;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .input-group {
    height: 66px;
    border-radius: 4px;
    border: 2px solid $orangePrimary;
  }

  .modal-header {
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      padding: 0;
      margin: 0;
    }
  }
}

.content-section-modal {
  label {
    color: $secondary
  }
}

.model-header {
  .model-title {
    color: $secondary;
    font-size: 30px !important;
    font-weight: 600;
    margin-bottom: 50px;
  }
}

.content-title {
  color: $secondary;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.07px;
}

.option-dimensions {
  color: rgba(108, 108, 108, 0.50);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.06px;
}

.content-option-card {
  position: relative;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(255, 107, 53, 0.10);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.content-option-card.selected {
  border: 3px solid rgba(255, 107, 53, 0.50);
}

.option-title {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.video-template-selection {
  h2 {
    color: $secondary;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.12px;
  }
}

.manual-property-model {
  h2 {
    color: $secondary;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.12px;
  }

  .input-field24 {
    border-radius: 5px;
    border: 1px solid #E5E7EB;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    margin-bottom: 28px;

    &::placeholder {
      color: rgba($secondary, 0.5);
      font-size: 14px;
    }
  }

  .form-label {
    color: $secondary;
    font-size: 14px;
    font-weight: 500;
  }

  .increase-button {
    font-size: 18px;
    padding: 10px;
    max-height: 44px;
    background-color: transparent;
    border: 0;
  }

  .decrease-button {
    font-size: 18px;
    padding: 10px;
    max-height: 44px;
    background-color: transparent;
    border-left: 1px solid #E5E7EB;
    border-right: 1px solid #E5E7EB;
    border-top: 0;
    border-bottom: 0;
  }

  .custom-radio {
    appearance: none;
    background-color: #fff;
    margin: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #e0e0e0;
    border-radius: 50%;
    display: inline-grid;
    place-content: center;
    cursor: pointer;

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: scale(0);
      transition: transform 0.2s ease-in-out;
      background-color: $orangePrimary; // Pink color for the inner circle
    }

    &:checked {
      border-color: $orangePrimary; // Pink border when checked

      &::before {
        transform: scale(0.9);
      }
    }

    &:hover {
      border-color: rgba($orangePrimary, 0.75); // Lighter pink on hover
    }
  }
}

.switch-pill {
  gap:10px;
  width: 100%;
  .nav-link {
    width: 200px;
    color: $secondaryGray;
font-size: 14px;
font-weight: 600;
border-radius: 5px;
background: #F7F8F9;
border: 1px solid transparent;
    &.active {
      background: $white !important;
      border-radius: 5px;
      border: 1px solid rgba(255, 107, 53, 0.50);
      color: $orangePrimary;
    }
  }
}

.switch-button {
  height: 30px;
  width: 156px;
  gap: 10px;
  border-radius: 5px;
  background: rgba(255, 107, 53, 0.10);

  .switch {
    background: transparent;
    color: $orangePrimary;
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 0;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease, padding 0.3s ease;

    &:hover {
      background: #fff;
      // padding: 5px 10px;
      border-radius: 5px;
    }

    &.active {
      background: #fff !important;
    }
  }
}

.view-toggle {
  display: flex;
  gap: 10px;
  .icon-wrapper {
    display: flex !important;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #E5E7EB;
    border-radius: 5px;
    &.active {
      background: #F7F8F9;
    }

    &:hover {
      background: #F7F8F9;
    }
  }
}

.addManual-property {
  .invalid-feedback {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.image-upload-label {
  color: $secondary;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  text-align: start;
  width: 100%;
}

.manual-image-upload {
  .drop {
    color: $secondary;
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.06px;
  }

  .browse {
    color: $orangePrimary;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.06px;
  }
}

.generation-action {
  .dropdown-toggle::after {
    border-right: 0 !important;
    border-left: 0 !important;
    margin: 0 !important;
    vertical-align: 0 !important;
  }

  .dropdown {
    .btn {
      --bs-btn-padding-x: 0.75rem;
      --bs-btn-padding-y: 0 !important;
      --bs-btn-line-height: unset !important;
      border: 1px solid #E5E7EB;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    }
  }
}

/* Bootstrap responsive breakpoints */
@media (max-width: 768px) {
  .content-options-grid {
    grid-template-columns: 1fr;
  }

  .content-creator-card {
    margin: 1rem;
  }

  .sidebar {
    width: 70px;
    min-width: 70px;
  }

  .sidebar .menu-title,
  .sidebar .profile-title,
  .sidebar .profile-email,
  .sidebar .sub-items,
  .sidebar .logo-text {
    display: none;
  }

  .sidebar .menu-item {
    padding: 12px;
    justify-content: center;
  }

  .sidebar .icon-container {
    margin-right: 0;
  }

  .main-content {
    margin-left: 70px;
  }

  /* When sidebar is not collapsed on mobile */
  .sidebar:not(.collapsed) {
    width: 260px;
    min-width: 260px;
  }

  .sidebar:not(.collapsed) .menu-title,
  .sidebar:not(.collapsed) .profile-title,
  .sidebar:not(.collapsed) .profile-email,
  .sidebar:not(.collapsed) .sub-items,
  .sidebar:not(.collapsed) .logo-text {
    display: block;
  }

  .sidebar:not(.collapsed) .menu-item {
    padding: 12px 20px;
    justify-content: flex-start;
  }

  .sidebar:not(.collapsed) .icon-container {
    margin-right: 15px;
  }

  .sidebar:not(.collapsed)+.main-content {
    margin-left: 260px;
  }

  /* When sidebar is collapsed on mobile */
  .sidebar.collapsed {
    width: 100px;
    min-width: 100px;
  }

  .sidebar.collapsed+.main-content {
    margin-left: 100px;
  }
}