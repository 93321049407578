// .ganesh-glory-ahmedabad {
//     position: absolute;
//     top: 388px;
//     left: 100px;
//     font-size: 70px;
//     font-weight: 300;
//     color: #f74a23;
//   }
//   .john-dao {
//     position: absolute;
//     top: 566px;
//     left: 100px;
//     font-size: 60px;
//   }
//   .email-id,
//   .mobile-no {
//     position: absolute;
//     top: 648px;
//     left: 100px;
//     font-weight: 300;
//   }
//   .email-id {
//     top: 691px;
//   }
//   .frame-child {
//     position: absolute;
//     top: 0;
//     left: 1070px;
//     background-color: #fff;
//     width: 850px;
//     height: 1080px;
//   }
//   .bannerimg-2-icon {
//     position: absolute;
//     top: 115px;
//     left: 1075px;
//     width: 845px;
//     height: 965px;
//     object-fit: cover;
//   }
//   .frame-item {
//     position: absolute;
//     top: 490px;
//     left: 99px;
//     border-top: 2px solid #f74a23;
//     box-sizing: border-box;
//     width: 872px;
//     height: 2px;
//   }
//   .ganesh-glory-ahmedabad-parent {
//     position: absolute;
//     top: 0;
//     left: 0;
//     background-color: #E03C31;
//     width: 1920px;
//     height: 1080px;
//     overflow: hidden;
//     font-size: 36px;
//   }
//   .welcome-to-this {
//     margin: 0;
//   }
//   .welcome-to-this-container {
//     position: relative;
//     letter-spacing: 0.05em;
//     display: inline-block;
//     width: 1720px;
//     flex-shrink: 0;
//   }
//   .welcome-to-this-charming-one-b-wrapper {
//     position: absolute;
//     top: 179px;
//     left: 100px;
//     width: 1716px;
//     height: 756px;
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//     justify-content: flex-start;
//   }
//   .title-here {
//     position: absolute;
//     top: 90px;
//     left: 100px;
//     font-size: 50px;
//     font-weight: 500;
//   }
//   .frame-group {
//     position: absolute;
//     top: 1135px;
//     left: 0;
//     background-color: #E03C31;
//     width: 1920px;
//     height: 1080px;
//     overflow: hidden;
//   }
//   .mask-group-icon {
//     position: relative;
//     width: 784px;
//     height: 880px;
//     object-fit: cover;
//   }
//   .mask-group-wrapper,
//   .title-here-wrapper {
//     position: absolute;
//     display: flex;
//     flex-direction: row;
//   }
//   .mask-group-wrapper {
//     top: 100px;
//     left: 99px;
//     width: 784px;
//     flex-wrap: wrap;
//     align-items: flex-end;
//     justify-content: flex-start;
//   }
//   .title-here-wrapper {
//     top: 96px;
//     left: 919px;
//     align-items: center;
//     justify-content: center;
//   }
//   .office-space {
//     position: relative;
//   }
//   .frame-parent1,
//   .office-space-wrapper {
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//   }
//   .office-space-wrapper {
//     flex: 1;
//     justify-content: flex-start;
//   }
//   .frame-parent1 {
//     width: 889px;
//     justify-content: space-between;
//   }
//   .new-property-wrapper {
//     width: 444.5px;
//     flex-direction: row;
//   }
//   .frame-div,
//   .frame-parent4,
//   .new-property-wrapper {
//     display: flex;
//     align-items: flex-start;
//     justify-content: flex-start;
//   }
//   .frame-parent4 {
//     width: 889px;
//     flex-direction: row;
//     gap: 263px;
//   }
//   .frame-div {
//     position: absolute;
//     top: 206px;
//     left: 929px;
//     flex-direction: column;
//     gap: 26px;
//     font-size: 30px;
//   }
//   .frame-container {
//     position: absolute;
//     top: 2270px;
//     left: 0;
//     background-color: #E03C31;
//     width: 1920px;
//     height: 1080px;
//     overflow: hidden;
//     font-size: 40px;
//   }
//   .mask-group-icon1 {
//     position: relative;
//     width: 431px;
//     height: 327px;
//     object-fit: cover;
//   }
//   .eulogia-inn-wrapper,
//   .mask-group-container {
//     position: absolute;
//     top: 0;
//     left: 0;
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//     justify-content: flex-start;
//   }
//   .eulogia-inn-wrapper {
//     top: 6px;
//     left: 461px;
//     align-items: center;
//     justify-content: center;
//   }
//   .open-now-wrapper {
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//     justify-content: flex-start;
//   }
//   .yes {
//     position: relative;
//     display: inline-block;
//     width: 991px;
//     flex-shrink: 0;
//   }
//   .frame-parent7,
//   .yes-wrapper {
//     width: 991px;
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//     justify-content: flex-start;
//   }
//   .frame-parent7 {
//     width: 1259px;
//     justify-content: space-between;
//   }
//   .group-child,
//   .group-child1,
//   .group-inner,
//   .group-item,
//   .star-icon {
//     position: absolute;
//     top: 4.47px;
//     left: 5.18px;
//     border-radius: 2px;
//     width: 42px;
//     height: 42px;
//   }
//   .group-child1,
//   .group-inner,
//   .group-item,
//   .star-icon {
//     left: 40.18px;
//   }
//   .group-child1,
//   .group-inner,
//   .star-icon {
//     left: 75.18px;
//   }
//   .group-child1,
//   .star-icon {
//     left: 110.18px;
//   }
//   .group-child1 {
//     left: 145.18px;
//   }
//   .b2 {
//     position: absolute;
//     top: 3px;
//     left: 198px;
//   }
//   .review,
//   .star-parent {
//     position: absolute;
//     left: 0;
//   }
//   .star-parent {
//     top: 0;
//     width: 246px;
//     height: 42px;
//   }
//   .review {
//     top: 50px;
//   }
//   .group-container {
//     position: relative;
//     width: 246px;
//     height: 86px;
//   }
//   .frame-parent6 {
//     position: absolute;
//     top: 88px;
//     left: 461px;
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     justify-content: flex-start;
//     gap: 20px;
//     font-size: 30px;
//   }
//   .group-div,
//   .star-group {
//     position: absolute;
//     top: 100px;
//     left: 100px;
//     width: 1720px;
//     height: 327px;
//   }
//   .star-group {
//     top: 0;
//     left: 0;
//     width: 245px;
//     height: 42px;
//   }
//   .group-parent1 {
//     position: relative;
//     width: 245px;
//     height: 86px;
//   }
//   .frame-parent10,
//   .group-parent {
//     position: absolute;
//     top: 451px;
//     left: 100px;
//     width: 1720px;
//     height: 327px;
//   }
//   .group-parent {
//     top: 3405px;
//     left: 0;
//     background-color: #E03C31;
//     width: 1920px;
//     height: 1080px;
//     overflow: hidden;
//     font-size: 40px;
//   }
//   .frame-inner {
//     position: absolute;
//     top: 96px;
//     left: 1034px;
//     width: 784px;
//   }
//   .frame-child1,
//   .mask-group-icon3,
//   .rectangle-icon {
//     position: relative;
//     width: 571px;
//     height: 880px;
//     object-fit: cover;
//   }
//   .frame-child1,
//   .rectangle-icon {
//     width: 432px;
//     height: 457px;
//   }
//   .frame-child1 {
//     height: 408px;
//   }
//   .mask-group-parent,
//   .rectangle-parent {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     justify-content: flex-start;
//     gap: 15px;
//   }
//   .mask-group-parent {
//     position: absolute;
//     top: 100px;
//     left: 801px;
//     flex-direction: row;
//   }
//   .frame-parent16,
//   .thaltej-metro-station-wrapper {
//     position: absolute;
//     left: 100px;
//     display: flex;
//     flex-direction: row;
//   }
//   .thaltej-metro-station-wrapper {
//     top: 106px;
//     align-items: center;
//     justify-content: center;
//     font-size: 40px;
//   }
//   .frame-parent16 {
//     top: 208px;
//     width: 500px;
//     align-items: flex-start;
//     justify-content: space-between;
//   }
//   .chowkdi-thaltej-rd {
//     position: relative;
//     display: inline-block;
//     width: 364px;
//     flex-shrink: 0;
//   }
//   .frame-parent17 {
//     position: absolute;
//     top: 278px;
//     left: 100px;
//     width: 500px;
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//     justify-content: space-between;
//   }
//   .frame-parent15 {
//     position: absolute;
//     top: 4560px;
//     left: 0;
//     background-color: #E03C31;
//     width: 1920px;
//     height: 1080px;
//     overflow: hidden;
//   }
//   .mask-group-group {
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//     justify-content: flex-start;
//     gap: 15px;
//   }
//   .sardar-vallabhbhai-patel {
//     position: relative;
//     display: inline-block;
//     width: 688px;
//     flex-shrink: 0;
//   }
//   .sardar-vallabhbhai-patel-inter-wrapper {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     font-size: 40px;
//   }
//   .frame-parent18,
//   .frame-parent19,
//   .frame-parent20 {
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//   }
//   .frame-parent20 {
//     width: 500px;
//     justify-content: space-between;
//     margin-left: -7px;
//   }
//   .frame-parent18,
//   .frame-parent19 {
//     justify-content: flex-start;
//   }
//   .frame-parent19 {
//     width: 500px;
//     flex-wrap: wrap;
//   }
//   .frame-parent18 {
//     position: absolute;
//     top: 100px;
//     left: 100px;
//     width: 1718px;
//     gap: 50px;
//   }
//   .frame-parent,
//   .frame-wrapper {
//     position: absolute;
//     left: 0;
//     width: 1920px;
//   }
//   .frame-wrapper {
//     top: 5700px;
//     background-color: #E03C31;
//     height: 1080px;
//     overflow: hidden;
//   }
//   .frame-parent {
//     top: 0;
//     height: 6780px;
//   }
//   .ppt-for-commercial {
//     position: relative;
//     width: 100%;
//     height: 6755px;
//     text-align: left;
//     font-size: 30px;
//     color: #fff;
//     font-family: "SF Pro Display";
//   }
  




.ganesh-glory-ahmedabad-com {
    position: absolute;
    top: 388px;
    left: 100px;
    font-size: 70px;
    font-weight: 300;
    color: #f74a23;
}
.john-dao-com {
    position: absolute;
    top: 566px;
    left: 100px;
    font-size: 60px;
}
.email-id-com,
.mobile-no-com {
    position: absolute;
    top: 648px;
    left: 100px;
    font-weight: 300;
}
.email-id-com {
    top: 691px;
}
.frame-child-com {
    position: absolute;
    top: 0;
    left: 1070px;
    background-color: #fff;
    width: 850px;
    height: 1080px;
}
.bannerimg-2-icon-com {
    position: absolute;
    top: 115px;
    left: 1075px;
    width: 845px;
    height: 965px;
    object-fit: cover;
}
.frame-item-com {
    position: absolute;
    top: 490px;
    left: 99px;
    border-top: 2px solid #f74a23;
    box-sizing: border-box;
    width: 872px;
    height: 2px;
}
.ganesh-glory-ahmedabad-parent-com {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #E03C31;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    font-size: 36px;
}
.welcome-to-this-com {
    margin: 0;
}
.welcome-to-this-container-com {
    position: relative;
    letter-spacing: 0.05em;
    display: inline-block;
    width: 1720px;
    flex-shrink: 0;
}
.welcome-to-this-charming-one-b-wrapper-com {
    position: absolute;
    top: 179px;
    left: 100px;
    width: 1716px;
    height: 756px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.title-here-com {
    position: absolute;
    top: 90px;
    left: 100px;
    font-size: 50px;
    font-weight: 500;
}
.frame-group-com {
    position: absolute;
    top: 1135px;
    left: 0;
    background-color: #E03C31;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
}
.mask-group-icon-com {
    position: relative;
    width: 784px;
    height: 880px;
    // object-fit: cover;
}
.mask-group-wrapper-com,
.title-here-wrapper-com {
    position: absolute;
    display: flex;
    flex-direction: row;
}
.mask-group-wrapper-com {
    top: 100px;
    left: 99px;
    width: 784px;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
}
.title-here-wrapper-com {
    top: 96px;
    left: 919px;
    align-items: center;
    justify-content: center;
}
.office-space-com {
    position: relative;
}
.frame-parent1-com,
.office-space-wrapper-com {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.office-space-wrapper-com {
    flex: 1;
    justify-content: flex-start;
}
.frame-parent1-com {
    width: 889px;
    justify-content: space-between;
}
.new-property-wrapper-com {
    width: 444.5px;
    flex-direction: row;
}
.frame-div-com,
.frame-parent4-com,
.new-property-wrapper-com {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.frame-parent4-com {
    width: 889px;
    flex-direction: row;
    gap: 263px;
}
.frame-div-com {
    position: absolute;
    top: 206px;
    left: 929px;
    flex-direction: column;
    gap: 26px;
    font-size: 30px;
}
.frame-container-com {
    position: absolute;
    top: 2270px;
    left: 0;
    background-color: #E03C31;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    font-size: 40px;
}
.mask-group-icon1-com {
    position: relative;
    width: 431px;
    height: 327px;
    // object-fit: cover;
}
.eulogia-inn-wrapper-com,
.mask-group-container-com {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.eulogia-inn-wrapper-com {
    top: 6px;
    left: 461px;
    align-items: center;
    justify-content: center;
}
.open-now-wrapper-com {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.yes-com {
    position: relative;
    display: inline-block;
    width: 991px;
    flex-shrink: 0;
}
.frame-parent7-com,
.yes-wrapper-com {
    width: 991px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.frame-parent7-com {
    width: 1259px;
    justify-content: space-between;
}
.group-child-com,
.group-inner-com,
.group-item-com,
.group-child1-com,
.star-icon-com {
    // position: absolute;
    top: 4.47px;
    left: 5.18px;
    border-radius: 2px;
    width: 42px;
    height: 42px;
}
.group-child1-com,
.group-inner-com,
.group-item-com,
.star-icon-com {
    left: 40.18px;
}
.group-child1-com,
.group-inner-com,
.star-icon-com {
    left: 75.18px;
}
.group-child1-com,
.star-icon-com {
    left: 110.18px;
}
.group-child1-com {
    left: 145.18px;
}
.b2-com {
    position: absolute;
    top: 3px;
    left: 198px;
}
.review-com,
.star-parent-com {
    position: absolute;
    left: 0;
}
.star-parent-com {
    top: 0;
    width: 246px;
    height: 42px;
}
.review-com {
    top: 50px;
}
.group-container-com {
    position: relative;
    width: 246px;
    height: 86px;
}
.frame-parent6-com {
    position: absolute;
    top: 88px;
    left: 461px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    font-size: 30px;
}
.group-div-com,
.star-group-com {
    position: absolute;
    top: 100px;
    left: 100px;
    width: 1720px;
    height: 327px;
}
.star-group-com {
    top: 0;
    left: 0;
    width: 245px;
    height: 42px;
}
.group-parent1-com {
    position: relative;
    width: 245px;
    height: 86px;
}
.frame-parent10-com,
.group-parent-com {
    position: absolute;
    top: 451px;
    left: 100px;
    width: 1720px;
    height: 327px;
}
.group-parent-com {
    top: 3405px;
    left: 0;
    background-color: #E03C31;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    font-size: 40px;
}
.frame-inner-com {
    position: absolute;
    top: 96px;
    left: 1034px;
    width: 784px;
}
.frame-child1-com,
.mask-group-icon3-com,
.rectangle-icon-com {
    position: relative;
    width: 571px;
    height: 880px;
    // object-fit: cover;
}
.frame-child1-com,
.rectangle-icon-com {
    width: 432px;
    height: 457px;
}
.frame-child1-com {
    height: 408px;
}
.mask-group-parent-com,
.rectangle-parent-com {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
}
.mask-group-parent-com {
    position: absolute;
    top: 100px;
   
    left: 801px;
    flex-direction: row;
}
.frame-parent16-com,
.thaltej-metro-station-wrapper-com {
    position: absolute;
    left: 100px;
    display: flex;
    flex-direction: row;
}
.thaltej-metro-station-wrapper-com {
    top: 106px;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}
.frame-parent16-com {
    top: 208px;
    width: 500px;
    align-items: flex-start;
    justify-content: space-between;
}
.chowkdi-thaltej-rd-com {
    position: relative;
    display: inline-block;
    width: 364px;
    flex-shrink: 0;
}
.frame-parent17-com {
    position: absolute;
    top: 278px;
    left: 100px;
    width: 500px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.frame-parent15-com {
    position: absolute;
    top: 4560px;
    left: 0;
    background-color: #E03C31;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
}
.mask-group-group-com {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
}
.sardar-vallabhbhai-patel-com {
    position: relative;
    display: inline-block;
    width: 688px;
    flex-shrink: 0;
}
.sardar-vallabhbhai-patel-inter-wrapper-com {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}
.frame-parent18-com,
.frame-parent19-com,
.frame-parent20-com {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.frame-parent20-com {
    width: 500px;
    justify-content: space-between;
    margin-left: -7px;
}
.frame-parent18-com,
.frame-parent19-com {
    justify-content: flex-start;
}
.frame-parent19-com {
    width: 500px;
    flex-wrap: wrap;
}
.frame-parent18-com {
    position: absolute;
    top: 100px;
    left: 100px;
    width: 1718px;
    gap: 50px;
}
.frame-parent-com,
.frame-wrapper-com {
    position: absolute;
    left: 0;
    width: 1920px;
}
.frame-wrapper-com {
    top: 5700px;
    background-color: #E03C31;
    height: 1080px;
    overflow: hidden;
}
.frame-parent-com {
    top: 0;
    height: 6780px;
}
.ppt-for-commercial-com {
    position: relative;
    width: 100%;
    height: 6755px;
    text-align: left;
    font-size: 30px;
    color: #fff;
    font-family: var(--font-lato);
}
