@import 'variables.scss';
.bgDarkBlue {
  background-color: #E03C31;
  min-height: 100vh;
}

.bgWhite {
  background-color: var(--white);
}

.bgBlack {
  background-color: var(--black);
}

.sign-up-child {
  position: absolute;
  top: -359px;
  left: -92px;
  width: 1750px;
  height: 1159px;
  display: none;
}

.logo-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--primary);
  width: 24px;
  height: 24px;
}

.logo-here {
  position: absolute;
  top: 2px;
  left: 34px;
  font-weight: 500;
}

.logo {
  position: relative;
  width: 90px;
  height: 37px;
  color: var(--primary);
  font-family: var(--font-AlumniSans);
}

.heading-3 {
  position: relative;
  font-weight: 600;
}

.heading3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  font-size: 24px;
  color: $primary;
  font-family: var(--font-roboto);
}

.email-id {
  position: relative;
  font-weight: 500;
}

.input-field {
  border-radius: 5px;
font-family: 'Roboto';
  background-color: var(--color-white);
  border: 1px solid #D1D5DB;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-xl);
  font-size: 15px;
  font-weight: 500;
  color:$primary;
}

.input-field::placeholder {
  color: var(--black, #000);
  font-weight: 500;
}

.login {
  position: relative;
  letter-spacing: 0.05em;
  font-weight: 600;
}

// .button {
//   border-radius: var(--br-11xl);
//   background-color: var(--primary);
//   // width: 400px;
//   cursor: pointer;
//   overflow: hidden;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   padding: var(--padding-mini) var(--padding-20xl);
//   box-sizing: border-box;
//   text-align: center;
//   color: var(--white);
//   font-size: 16px;
//   border: 0;
//   text-transform: uppercase;
// }

.span {
  color: var(--black);
  font-size: 18px;
}

.login1 {
  color:$primary;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
font-family: 'Roboto';
}

.already-have-an-container {
  position: relative;
  color:$primary;
    font-size: 16px;
    font-weight: 500;
  font-family: 'Roboto';
}

.already-have-an-account-login-wrapper {
  flex-direction: row;
}

.already-have-an-account-login-wrapper,
.back-to-home-wrapper,
.logo-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-to-home-wrapper {
  flex-direction: row;
  text-align: center;
  color: var(--primary);
  font-size: 18px;
  text-decoration: none;
}

.logo-parent {
  border-radius: 20px;
  background-color: var(--color-white);
  flex-direction: column;
  padding: 23px 25px;
  gap: 20px;
  max-width: 520px;
  width: 90%;
}

.sign-up {
  // background-color: var(--primary);
  // background: var(--Accent, #F2F2F2);
  border-radius: 20px;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--black);
  font-family: var(--font-AlumniSans);
  display: flex;
  justify-content: center;
  align-items: center;
  
  .heading{
    color:$primary;
  font-family: 'Roboto';
    font-weight: 600;
    font-size: 30px;
    text-transform: uppercase;
  }
}
.signup-bg{
  background:url('../images/Signup.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.login-bg{
  background:url('../images/Login.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.google-icon {
  position: relative;
  width: 34px;
  height: 34px;
}

.google-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xl);
  border:1px solid #E5E7EB;
  border-radius: 5px;
  height: 46px;
  width: 100%;
  span{
  font-family: 'Roboto';
    font-weight: 600;
    color:$primary;
    margin-left: 14px;
  }
}

.or-login-with {
  position: relative;
  letter-spacing: 0.02em;
  color: #6B728080;
font-family:'Roboto';
font-size: 15px;
margin: 20px 0;
}

.line-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 18px;
  color: var(--black);
  width: 300px;
}

.dont-have-an-account-sign-up-wrapper,
.logo-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dont-have-an-account-sign-up-wrapper {
  flex-direction: row;
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
}

.forgot-password {
  position: relative;
  span{
  font-family: 'Roboto';
    font-size: 16px;
  }
}

.forgot-password-wrapper {
  color:$primary;
  font-size: 15px;
font-family: 'Roboto';
  font-weight: 600 !important;
  text-decoration: none;
  text-align: start;
  width: 100%;
  line-height: 0;
}

.frame-child {
  position: relative;
  border-top: 1px solid #6B728040;
  box-sizing: border-box;
  width: 110px;
  height: 1px;
}

.otp-child {
  position: absolute;
  top: -359px;
  left: -92px;
  width: 1750px;
  height: 1159px;
  display: none;
}

.otp-item {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.otp {
  position: relative;
  height: 400px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--black);
  font-family: var(--font-AlumniSans);
}

.a-4-digit {
  margin: 0;
}

.a-4-digit-container {
  flex: 1;
  position: relative;
}

.description {
  width: 367px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-3xs) 0;
  box-sizing: border-box;
  text-align: center;
}

.input-field-parent,
.input-field4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-field4 {
  border-radius: var(--br-11xl);
  background-color: var(--color-white);
  border: 1px solid var(--gray);
  box-sizing: border-box;
  width: 47px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-xl);
}

.input-field-parent {
  align-self: stretch;
  flex-wrap: wrap;
  justify-content: center;
  padding: var(--padding-3xs) 0 var(--padding-xl);
  gap: var(--gap-3xl);
}

.resend-otp {
  color: var(--primary);
  background: none;
  border: none;
  font-weight: 700;
}
.frame-parent-otp {
  border-radius: var(--br-xl);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-4xl) var(--padding-6xl);
  gap: var(--gap-3xl);
}

.custom-otp-modal {
  .modal-content {
    margin-left: 33px;
    max-width: 435px;
  }
}

.heading3-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-5xl);
  color: var(--primary);
}

.enter-the-email {
  margin: 0;
}

.enter-the-email-container {
  flex: 1;
  position: relative;
}

.forgot-password-desc {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  color: var(--black);
}
.heading3-parent-otp {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-5xl);
  color: var(--primary);
}

.bordered-image {
  border: 4px solid var(--primary);
  border-radius: 50%;
  background: transparent;
}

.input-field-mobile {
  background-color: var(--color-white);
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.otpInput {
  input {
    border-radius: 30px !important;
    border: 1px solid var(--Gray, #ccc);
    background: #fff;
    display: flex;
    width: 47px !important;
    padding: 15px !important;
    align-items: center;
    gap: 10px;
    height: 51px !important;
  }
}

