@media (max-width:1200px) {
    .font30White {
        font-size: 25px;
    }
    .font40WhitePPT {
        font-size: 22px;
    }
    .font30WhitePPT {
        font-size: 18px;
    }
    .font20WhitePPT {
        font-size: 16px;
    }
    .fs20 {
        font-size: 18px;
    }
    .fs16 {
        font-size: 15px;
    }
}
@media (max-width:992px) {
    .custom-videoplayer {
        padding: 30px;
    }
     .navlink-parent {
        flex-direction: column;
    }
    .font30White {
        font-size: 30px;
        justify-content: center;
    }
    .font40WhitePPT {
        font-size: 20px;
    }
    .font30WhitePPT {
        font-size: 16px;
    }
    .font20WhitePPT {
        font-size: 14px;
    }
    .fs16 {
        font-size: 13px;
    }
    .fs20 {
        font-size: 14px;
    }
}

@media (max-width: 767.98px) {
    .custom-videoplayer {
        padding: 20px;
    }
   
    .fs60 {
        font-size: 30px;
    }
   
    .pricing .subheading-parent .switch-vertical {
        margin-left: 0;
        height: auto;
    }
    .pricing .subheading-parent .switch-vertical label {
        font-size: 14px;
    }
    .pricing .subheading-parent {
        margin-bottom: 20px;
    }
    // .pricing .subheading-parent .switch-vertical input:checked ~ .toggle-outside .toggle-inside {
    //     left: 93px;
    // }
    .font30White {
        font-size: 30px;
    }
    .font40WhitePPT {
        font-size: 24px;
    }
    .font30WhitePPT {
        font-size: 20px;
    }
    .font20WhitePPT {
        font-size: 16px;
    }
    .pptRoomImg, .pptMainImg {
        height: auto;
    }
    .fs16 {
        font-size: 12px;
    }
    .fs20 {
        font-size: 15px;
    }
    .ImageEditorcard .card_body {
        flex-direction: column-reverse;
    }
    .ImageEditorcard .card_body .image_section {
        width: 100% !important;
        margin-left: 0 !important;
    }
    .imageCrop-sidebar {
        margin-top: 100px !important;
    }
    .ImageEditorcard .card_body .sidebar .side_body {
        width: 100% !important;
    }
}
@media (max-width:575.95px) {
    .frame-child {
        width: 70px;
    }
    .line-parent {
        width: 200px;
    }
    .form-section {
        padding: 1rem;
    }
    .property-link-form {
        padding: 0rem;
      }
    .description-tab .nav-link {
        width: 130px;
        font-size: 16px;
    }
    .custom-videoplayer {
        padding: 10px;
    }
    .custom-videoplayer h3 {
        font-size: 20px;
    }
    .font50OrangePPT {
        font-size: 30px;
    }
    .p-50 {
        padding: 20px;
    }
    .font30Blue {
        font-size: 24px;
    }
    .heading3 {
        font-size: 20px;
    }
    .font24Black {
        font-size: 18px;
    }
    .frame-group-add {
        padding: 0;
    }
    .checkboxBG {
        flex-wrap: wrap;
    }
    .checkboxBG .checkboxPart {
        margin: 0 0 10px 0;
        width: 100%;
    }
    .frame-parent6, .input-field2-parent12 {
        display: block;
    }
    .frame-wrapper5,
    .input-field222 {
        display: block;
    }
    .fs30 {
        font-size: 24px;
    }
    .FAQSection .accordion-button {
        font-size: 16px;
    }
    .FAQSection .accordion-body {
        font-size: 14px;
    }
    .pricing .subheading .home {
        max-width: 100% !important;
    }
    .font20Blk {
        font-size: 16px;
    }
    .font24Blue {
        font-size: 18px;
    }
    .icContact {
        width: 40px;
        height: 40px;
        svg {
            width: 24px;
        }
    }
    .customInviteMemberInputArea input {
        width: 100%;
    }
    .customInviteMemberInputArea select {
        padding: 5px;
    }
}
@media (min-width: 280px) {
    .fs60 {
      font-size: calc(1.875rem + ((1vw - 2.8px) * 1.8293));
      min-height: 0vw;
    }
}