@import 'variables.scss';

.font30-semibold-primary {
    color: $primary;
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 600;
}
.font16-semibold-orange{
color: $orangePrimary;
font-family:'Roboto';
font-size: 16px;
font-weight: 600;
}
.font24-medium-primary {
    color: $primary;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 500;
}
.font24-semibold-primary {
    color: $primary;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 600;
}
.font16-bold-primary {
    color: $primary;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;
}

.font14-semibold-primary {
    color: $primary;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 600;
}
.font14-medium-primary {
    color: $primary;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
}
.font14-normal-primary {
    color: $primary;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
}

.font12-medium-primary {
    color: $primary;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
}
.font12-semibold-primary {
    color: $primary;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 600;
}

