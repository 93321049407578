.ganesh-genesis-ahmedabad-ppt {
  position: absolute;
  top: 388px;
  left: 100px;
  font-size: 70px;
  font-weight: 300;
  color: #f74a23;
}
.john-dao-ppt {
  position: absolute;
  top: 566px;
  left: 100px;
  font-size: 60px;
}
.email-id-ppt,
.mobile-no-ppt {
  position: absolute;
  top: 648px;
  left: 100px;
  font-weight: 300;
}
.email-id-ppt {
  top: 691px;
}
.frame-child-ppt {
  position: absolute;
  top: 0;
  left: 1070px;
  background-color: #fff;
  width: 850px;
  height: 1080px;
}
.bannerimg-2-icon-ppt {
  position: absolute;
  top: 115px;
  left: 1075px;
  width: 845px;
  height: 965px;
  object-fit: cover;
}
.frame-item-ppt {
  position: absolute;
  top: 490px;
  left: 99px;
  border-top: 2px solid #f74a23;
  box-sizing: border-box;
  width: 872px;
  height: 2px;
}
.ganesh-genesis-ahmedabad-parent-ppt {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e03c31;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  font-size: 36px;
}
.welcome-to-this-ppt {
  margin: 0;
}
.welcome-to-this-container-ppt {
  position: relative;
  letter-spacing: 0.05em;
  display: inline-block;
  width: 1720px;
  flex-shrink: 0;
}
.welcome-to-this-charming-one-b-wrapper-ppt {
  position: absolute;
  top: 179px;
  left: 100px;
  width: 1716px;
  height: 756px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.title-here-ppt {
  position: absolute;
  top: 90px;
  left: 100px;
  font-size: 50px;
  font-weight: 500;
}
.frame-group-ppt {
  position: absolute;
  top: 1135px;
  left: 0;
  background-color: #e03c31;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
}
.mask-group-icon-ppt {
  position: relative;
  width: 784px;
  height: 880px;
  // object-fit: cover;
}
.mask-group-wrapper-ppt,
.title-here-wrapper-ppt {
  position: absolute;
  display: flex;
  flex-direction: row;
}
.mask-group-wrapper-ppt {
  top: 100px;
  left: 99px;
  width: 784px;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
}
.title-here-wrapper-ppt {
  top: 96px;
  left: 919px;
  align-items: center;
  justify-content: center;
}
.living-ppt {
  position: relative;
}
.frame-parent1-ppt,
.living-wrapper-ppt {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.living-wrapper-ppt {
  flex: 1;
  justify-content: flex-start;
}
.frame-parent1-ppt {
  width: 889px;
  justify-content: space-between;
}
.new-property-wrapper-ppt {
  width: 444.5px;
  flex-direction: row;
}
.frame-div-ppt,
.frame-parent7-ppt,
.new-property-wrapper-ppt {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent7-ppt {
  width: 889px;
  flex-direction: row;
  gap: 263px;
}
.frame-div-ppt {
  position: absolute;
  top: 206px;
  left: 929px;
  flex-direction: column;
  gap: 26px;
  font-size: 30px;
}
.frame-container-ppt {
  position: absolute;
  top: 2270px;
  left: 0;
  background-color: #e03c31;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  font-size: 40px;
}
.mask-group-icon1-ppt {
  position: relative;
  width: 431px;
  height: 327px;
  // object-fit: cover;
}
.mask-group-container-ppt,
.ranus-kitchen-wrapper-ppt {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.ranus-kitchen-wrapper-ppt {
  top: 6px;
  left: 461px;
  align-items: center;
  justify-content: center;
}
.open-now-wrapper-ppt {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.yes-ppt {
  position: relative;
  display: inline-block;
  width: 991px;
  flex-shrink: 0;
}
.frame-parent11-ppt,
.yes-wrapper-ppt {
  width: 991px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent11-ppt {
  width: 1259px;
  justify-content: space-between;
}
.group-child-ppt,
.group-child1-ppt,
.group-inner-ppt,
.group-item-ppt,
.star-icon-ppt {
  position: absolute;
  top: 4.47px;
  left: 5.18px;
  border-radius: 2px;
  width: 42px;
  height: 42px;
}
.group-child1-ppt,
.group-inner-ppt,
.group-item-ppt,
.star-icon-ppt {
  left: 40.18px;
}
.group-child1-ppt,
.group-inner-ppt,
.star-icon-ppt {
  left: 75.18px;
}
.group-child1-ppt,
.star-icon-ppt {
  left: 110.18px;
}
.group-child1-ppt {
  left: 145.18px;
}
.b2-ppt {
  position: absolute;
  top: 3px;
  left: 198px;
}
.review-ppt,
.star-parent-ppt {
  position: absolute;
  left: 0;
}
.star-parent-ppt {
  top: 0;
  width: 246px;
  height: 42px;
}
.review-ppt {
  top: 50px;
}
.group-container-ppt {
  position: relative;
  width: 246px;
  height: 86px;
}
.frame-parent10-ppt {
  position: absolute;
  top: 88px;
  left: 461px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  font-size: 30px;
}
.group-div-ppt,
.star-group-ppt {
  position: absolute;
  top: 100px;
  left: 100px;
  width: 1720px;
  height: 327px;
}
.star-group-ppt {
  top: 0;
  left: 0;
  width: 245px;
  height: 42px;
}
.group-parent1-ppt {
  position: relative;
  width: 245px;
  height: 86px;
}
.frame-parent14-ppt,
.group-parent-ppt {
  position: absolute;
  top: 451px;
  left: 100px;
  width: 1720px;
  height: 327px;
}
.group-parent-ppt {
  top: 3405px;
  left: 0;
  background-color: #e03c31;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  font-size: 40px;
}
.frame-inner-ppt,
.mask-group-icon3-ppt,
.rectangle-icon-ppt {
  position: relative;
  width: 571px;
  height: 880px;
  // object-fit: cover;
}
.frame-inner-ppt,
.rectangle-icon-ppt {
  width: 432px;
  height: 457px;
}
.rectangle-icon-ppt {
  height: 408px;
}
.mask-group-parent-ppt,
.rectangle-parent-ppt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}
.mask-group-parent-ppt {
  flex-direction: row;
}
.day-to-day-supermarket-wrapper-ppt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}
.frame-parent21-ppt {
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -7px;
}
.shop-09-shree-vishnudhara-ppt {
  position: relative;
  display: inline-block;
  width: 364px;
  flex-shrink: 0;
}
.frame-parent19-ppt,
.frame-parent20-ppt {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent20-ppt {
  width: 500px;
  flex-wrap: wrap;
}
.frame-parent19-ppt {
  position: absolute;
  top: 100px;
  left: 100px;
  width: 1718px;
  gap: 50px;
}
.frame-wrapper-ppt {
  position: absolute;
  top: 4540px;
  left: 0;
  background-color: #e03c31;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
}
.frame-child1-ppt {
  position: absolute;
  top: 96px;
  left: 1034px;
  width: 784px;
}
.mask-group-group-ppt {
  position: absolute;
  top: 100px;
  left: 801px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}
.centre-point-wrapper-ppt,
.frame-parent24-ppt,
.frame-parent25-ppt {
  position: absolute;
  left: 100px;
  display: flex;
  flex-direction: row;
}
.centre-point-wrapper-ppt {
  top: 106px;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}
.frame-parent24-ppt,
.frame-parent25-ppt {
  top: 208px;
  width: 500px;
  align-items: flex-start;
  justify-content: space-between;
}
.frame-parent25-ppt {
  top: 278px;
}
.frame-parent23-ppt,
.frame-wrapper1-ppt {
  background-color: #e03c31;
  height: 1080px;
  overflow: hidden;
}
.frame-parent23-ppt {
  position: absolute;
  top: 5675px;
  left: 0;
  width: 1920px;
}
.frame-wrapper1-ppt {
  top: 6854px;
}
.frame-parent-ppt,
.frame-parent30-ppt,
.frame-wrapper1-ppt {
  position: absolute;
  left: 0;
  width: 1920px;
}
.frame-parent30-ppt {
  top: 7989px;
  background-color: #e03c31;
  height: 1080px;
  overflow: hidden;
}
.frame-parent-ppt {
  top: 0;
  height: 9069px;
}
.ppt-for-residential-ppt {
  position: relative;
  // height: 6755px;
  text-align: left;
  font-size: 30px;
  color: #fff;
font-family: 'Roboto';
}

// new style  start
.pptPage {
  padding: 45px 0;
  background-color: var(--Primary);
}
.pptSlide {
  background: var(--Primary);
}
.p-50 {
  padding: 50px;
}
.font50Orange {
  color: #E03C31;
font-family: 'Roboto';
  font-size: 50px;
  font-style: normal;
  font-weight: bold;
}
.font50OrangePPT {
  color: #ffffff;
font-family: 'Roboto';
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  // text-decoration: underline;
  border-bottom: solid 2px #ffffff;
  margin-bottom: 50px;
}
.font40WhitePPT {
  color: var(--White, #fff);
font-family: 'Roboto';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.font30WhitePPT {
  color: var(--White, #fff);
font-family: 'Roboto';
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.font40WhitePPT {
  color: var(--White, #fff);
font-family: 'Roboto';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.font20WhitePPT {
  color: var(--White, #fff);
font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1.5px;
}
.font20WhiteBoldPPT {
  color: var(--White, #fff);
font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.5px;
}
.font-bold{
  font-weight: bold;
}
.pptMainImg {
  width: 100%;
  height: 650px;
}
.pptRoomImg {
  width: 100%;
  height: 550px;
}
.photos-grid-container {
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  align-items: start;
}
.photos-grid-container .sub {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
}
.photos-grid-container .main-photo {
  grid-row: 1;
  grid-column: 1;
}
.photos-grid-container img {
  max-width: 100%;
  display: block;
  height: auto;
}
// new style  start
.box_Property_Images {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  gap: 30px;
}
.Property_Images {
  // width: 200px;
  // height: 200px;
  aspect-ratio: 1/1;
  width: 350px;
}
.propertyImages_text {
  color: #fff;
  font-size: 20px;
}

// New style start
.Property_Cards {
  padding: 12px;
  border-radius: 30px;
  min-height: 230px;
  .Card_Image {
    // width: 100%;
    // height: 200px;
    width: 255px;
    // max-height: 150px;
    border-radius: 20px;
    aspect-ratio: 4/3;
  }
  .Property_Cards_title {
    font-weight: 800;
  }
  .Property_Cards_text_heading {
    font-weight: 700;
    margin-right: 5px;
  }
  .Property_Cards_rating {
    display: flex;
    align-items: center;
  }
  .card-body {
    padding: 0;
  }
}

.newPptSlide {
  background-color: var(--white, #FFF) !important;
  background: url("../images/ppt_bg.png"), lightgray 50% / cover no-repeat;
  background-size: cover;
  min-height: 600px;
  max-height: 650px;
  overflow-y: auto;
  margin-bottom: 50px;

  .propertyDetails {
    height: 350px ;
    width: 350px ;
    position: absolute ;
    top: 150px ;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 350px;
    opacity: 0.9;
    background: linear-gradient(90deg, rgba(239,52,42,1) 0%, rgba(250,166,59,1) 100%);

    @media (max-width: "768px") {
      height: 250px;
      width: 250px;
      top: 150;
      border-radius: 250px;
    }
  }

  .part {
    height: 80px;
    width: 20px;
    background: var(--Primary, #e03c31);
  }

  .pptHeader {
    background: rgba(255, 255, 255, 0.5);
    height: 80px;
    width: 100%;
    color: var(--Primary, #e03c31);
  font-family: 'Roboto';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .propertChildImage {
    aspect-ratio: 1/1;
    border-radius: 30px;
    width: 180px;
  }

  .propertyImage {
    aspect-ratio: 1/1;
    border-radius: 30px;
    width: 310px;
  }

  .roomImage {
    // aspect-ratio: 0.8 / 1;
    width: 100%;
    max-width: 365px;
    border-radius: 30px;
  }

  .imgDiv {
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    position: relative;
    width: 100%;
    // min-height: 100%;

    &_imgContainer {
        position: relative;
        width: 100%;
        padding-top: calc(100 / 99 * 100%);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
    }
}
}

.font30BlackPPT {
  color: var(--secondary, #333);
font-family: 'Roboto';
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.font20BlackPPT {
  color: var(--secondary, #333);
font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.newfont40WhitePPT {
  color: var(--White, #fff);
  text-align: center;
font-family: 'Roboto';
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.newfont30WhitePPT {
  color: var(--White, #fff);
  text-align: center;
font-family: 'Roboto';
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.newfont20WhitePPT {
  color: var(--White, #fff);
  text-align: center;
font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}


.font24BlackPPT {
  color: var(--secondary, #333);
font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;
}

.font22BlackPPT {
  color: var(--secondary, #333);
font-family: 'Roboto';
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.font20BlackPPT {
  color: var(--secondary, #333);
font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.font18BlackPPT {
  color: var(--secondary, #333);
font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.9px;
}

.font16BlackPPT {
  color: var(--secondary, #333);
font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.9px;
}

.font16BoldBlackPPT {
  color: var(--secondary, #333);
font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
}

.font18BoldBlackPPT {
  color: var(--secondary, #333);
font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.font20BoldBlackPPT {
  color: var(--secondary, #333);
font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.font30BoldPPTRed {
  color: var(--Primary,#005652);
font-family: 'Roboto';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}

.font30BoldBlackPPT {
  color: var(--secondary, #333);
font-family: 'Roboto';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;
}
