@import 'variables.scss';

.description-header {
  // margin-top: 35px;
  width:100%;
}

.description-page {
  padding-bottom: 50px;
  border-radius: 30px;
  background: $white;
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 38px 44px;
}

.description-tab {
  position: relative;
  ul {
    gap: 10px;
  }

  .nav-item {
    border-radius: 10px 10px 0px 0px;
    font-size: 28px;
    background: var(--gray, #CCC);
    backdrop-filter: blur(2px);
    color: var(--secondary);
    .active {
      border-radius: 10px 10px 0px 0px;
      background: $orangePrimary;
      color: #fff
    }
  }

  .nav-link {
    color: var(--secondary);
  }

  .tab-content {
    border: 2px solid var(--secondary, #333);
    padding: 20px;
    gap: 10px;
  font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    width: 100%;

    .line {
      height: 2px;
      background: var(--primary);
    }

    .copy-content {
      display: flex;
      padding: 15px 26px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 30px;
      background: var(--primary);
      color: var(--White, #fff);
      text-align: center;
      font-family: var(--font-AlumniSans);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.8px;
      border: none;
    }

    .share {
      gap: 20px;
    }
  }
}

.custom-videoplayer {
  position: relative;
  // padding: 50px 100px 60px;
  // background: $orangePrimary;
  // min-height: 300px;

  h3 {
    color: var(--White, #fff);
    font-family: var(--font-roboto);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .video-content {
    display: flex;
    padding: 15px 26px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 30px;
    background: var(--White, #fff);
    color: var(--secondary);
    text-align: center;
    font-family: var(--font-AlumniSans);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
    border: none;
  }
}
.breadcrumbCustom .breadcrumb-item {
   a {
    color: var(--white);
    font-weight: bold;
    text-decoration: none;
   }
   &.active {
    color: var(--white);
   }
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--white);
}