@import 'variables.scss';
.property {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 230px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-11xl);
  color: var(--primary);
  font-family: var(--font-AlumniSans);
  .logo-child,
  .property-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 1750px;
    height: 1159px;
  }
  .logo-child {
    border-radius: 50%;
    background-color: var(--primary);
    width: 24px;
    height: 24px;
  }
  .logo-here {
    position: absolute;
    top: 2px;
    left: 34px;
    font-weight: 500;
  }
  .logo {
    width: 113px;
    height: 24px;
  }
  .home,
  .logo {
    position: relative;
  }
  .navlink {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs);
  }
  .login {
    position: relative;
    letter-spacing: 0.05em;
    font-weight: 600;
    text-decoration: none;
  }
  .vector-icon {
    position: relative;
    width: 19px;
    height: 12px;
    display: none;
  }
  .button-icon,
  .login-parent {
    display: flex;
    justify-content: flex-start;
  }
  .login-parent {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-6xs);
  }
  .button-icon {
    border-radius: var(--br-11xl);
    background-color: var(--primary);
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--padding-mini) var(--padding-7xl);
    text-align: center;
    color: var(--white);
  }
  .heading-1-wrapper {
    top: 259px;
    left: calc(50% - 203px);
    font-size: var(--font-size-17xl);
  }
  .subheading {
    position: absolute;
    top: 320px;
    left: calc(50% - 285px);
    font-size: var(--font-size-xl);
    color: var(--black);
  }
  .subheading,
  .subheading1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .group-child,
  .group-item {
    position: absolute;
    border-radius: var(--br-11xl);
  }
  .group-child {
    top: -2px;
    left: -2px;
    background-color: #eaf4ff;
    border: 2px solid var(--primary);
    box-sizing: border-box;
    width: 76px;
    height: 40px;
  }
  .group-item {
    top: 3px;
    left: 39px;
    background-color: var(--color-limegreen);
    width: 30px;
    height: 30px;
  }
  .rectangle-parent {
    position: relative;
    width: 72px;
    height: 36px;
  }
  .subheading-parent {
    position: absolute;
    top: 442px;
    left: 591px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
    font-size: var(--font-size-xl);
    color: var(--black);
    .toggle-switch-container {
      align-content: center;
    }

    .toggle-switch {
      margin: 0;
    }

    .toggle-switch {
      width: 10px;
      position: relative;
    }

    .toggle-switch input {
      position: absolute;
      top: 0;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }

    .toggle-switch input:checked {
      z-index: 1;
    }

    .toggle-switch input:checked + label {
      opacity: 1;
      cursor: default;
    }

    .toggle-switch input:not(:checked) + label:hover {
      opacity: 0.5;
    }

    .toggle-switch .toggle-outside {
      height: 100%;
      border-radius: 17px;
      padding: 11px;
      overflow: hidden;
      transition: opacity 0.3s ease-in-out;
    }

    .toggle-switch .toggle-inside {
      border-radius: 30px;
      background: #424242;
      position: absolute;
      transition: all 0.3s ease-in-out;
    }

    .switch-vertical {
      width: auto;
      height: 70px;
      margin-left: 27px;
    }

    .switch-vertical input {
      height: 100%;
      width: 60px;
      right: 0;
      margin: 0;
    }

    .toggle-switch label {
      color: #c4cfda;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
    }

    .switch-vertical label {
      font-size: 16px;
      line-height: 25px;
      font-weight: 600;
      // display: flex;
      width: auto;
      // height: 50%;
      margin: 0;
      text-align: left;
      padding-left: 12px;
    }

    .toggle-switch span.normal {
      color: #53575a;
      font-size: 16px;
      font-weight: 600;
      padding-left: 12px;
    }

    .switch-vertical .toggle-outside {
      top: -2px;
      left: -2px;
      background-color: #eaf4ff;
      border: 2px solid var(--primary);
      box-sizing: border-box;
      width: 76px;
      height: 40px;
    }

    .switch-vertical .toggle-inside {
      top: 4px;
      left: 95px;
      background-color: var(--color-limegreen);
      width: 30px;
      height: 30px;
    }

    .switch-vertical input:checked ~ .toggle-outside .toggle-inside {
      top: 3px;
    }

    .switch-vertical input ~ input:checked ~ .toggle-outside .toggle-inside {
      top: 35px;
    }
  }
  .heading-2 {
    position: relative;
    font-weight: 600;

    color: var(--White, #fff);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    // line-height: normal;
  }
  .heading-2-container,
  .heading-2-wrapper,
  .month-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .heading-2-wrapper {
    top: 386px;
    left: 643px;
  }
  .heading-2-container,
  .month-wrapper {
    top: 24px;
    left: calc(50% - 38px);
  }
  .month-wrapper {
    top: 53px;
    left: calc(50% - 53px);
    padding: var(--padding-3xs) 0;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--gray);
  }
  .property-description {
    margin-bottom: 0;
  }
  .property-description-1-user-lo {
    margin: 0;
    // padding-left: var(--padding-8xl);
  }
  .property-description-1-container {
    position: relative;
    line-height: 30px;
  }
  .button-icon1,
  .subheading3 {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .subheading3 {
    padding: 0px 30p;
    top: 119px;
    left: calc(50% - 124px);
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    font-size: var(--font-inherit);
    font-family: var(--font-AlumniSans);
  }
  .button-icon1 {
    top: 328px;
    left: calc(50% - 67px);
    border-radius: var(--br-11xl);
    background-color: var(--gray);
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-mini) var(--padding-7xl);
    font-size: var(--font-size-base);
    color: var(--primary);
  }
  .instance-parent {
    position: relative;
    border-radius: var(--br-xl);
    background-color: rgba(9, 50, 84, 0.9);
    backdrop-filter: blur(4px);
    width: 350px;
    height: 404px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .heading-2-frame,
  .month-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .month-container {
    top: 53px;
    left: calc(50% - 53px);
    padding: var(--padding-3xs) 0;
    color: var(--gray);
  }
  .heading-2-frame {
    top: 24px;
    left: calc(50% - 65px);
    text-align: center;
    font-size: var(--font-size-11xl);
  }
  .button-icon2,
  .subheading4 {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .subheading4 {
    top: 119px;
    left: calc(50% - 129px);
    flex-direction: row;
    flex-wrap: wrap;
    font-size: var(--font-inherit);
    font-family: var(--font-AlumniSans);
  }
  .button-icon2 {
    top: 328px;
    left: calc(50% - 67px);
    border-radius: var(--br-11xl);
    background-color: var(--gray);
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-mini) var(--padding-7xl);
    text-align: center;
    font-size: var(--font-size-base);
    color: var(--primary);
  }
  .frame-group {
    position: relative;
    border-radius: var(--br-xl);
    background-color: rgba(9, 50, 84, 0.8);
    backdrop-filter: blur(4px);
    width: 350px;
    height: 404px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: left;
    font-size: var(--font-size-xl);
  }
  .button-icon3,
  .frame-div,
  .month-frame {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .month-frame {
    top: 53px;
    left: calc(50% - 58px);
    padding: var(--padding-3xs) 0;
    color: var(--gray);
  }
  .button-icon3,
  .frame-div {
    top: 24px;
    left: calc(50% - 87px);
    text-align: center;
    font-size: var(--font-size-11xl);
  }
  .button-icon3 {
    top: 328px;
    left: calc(50% - 71.5px);
    border-radius: var(--br-11xl);
    background-color: var(--gray);
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-mini) var(--padding-7xl);
    font-size: var(--font-size-base);
    color: var(--primary);
  }
  .frame-container {
    position: relative;
    border-radius: var(--br-xl);
    background-color: rgba(9, 50, 84, 0.7);
    backdrop-filter: blur(4px);
    width: 350px;
    height: 404px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: left;
    font-size: var(--font-size-xl);
    gap: 14px;
  }
  .heading-2-wrapper1 {
    position: absolute;
    top: 24px;
    left: calc(50% - 87px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .need-additional-seats {
    position: relative;
    line-height: 30px;
    display: inline-block;
    width: 282px;
    flex-shrink: 0;
  }
  .button-icon4,
  .subheading6 {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .subheading6 {
    top: 119px;
    left: calc(50% - 141px);
    flex-direction: row;
    flex-wrap: wrap;
    font-size: var(--font-size-xl);
  }
  .button-icon4 {
    top: 328px;
    left: calc(50% - 76px);
    border-radius: var(--br-11xl);
    background-color: var(--gray);
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-mini) var(--padding-7xl);
    font-size: var(--font-size-base);
    color: var(--primary);
  }
  .instance-group {
    position: relative;
    border-radius: var(--br-xl);
    background-color: var(--primary);
    backdrop-filter: blur(4px);
    width: 350px;
    height: 404px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .frame-parent,
  .heading-2-wrapper2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .frame-parent {
    position: absolute;
    top: 521px;
    left: calc(50% - 552px);
    width: 1104px;
    flex-wrap: wrap;
    gap: 23px;
    color: var(--white);
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heading-2-wrapper2 {
    align-items: center;
  }
  .bannerimg-1-icon {
    position: relative;
    width: 162px;
    height: 151px;
    object-fit: cover;
  }
  .instance-container {
    position: absolute;
    top: 78px;
    left: calc(50% - 720px);
    background-color: var(--primary);
    width: 1440px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 84px;
    box-sizing: border-box;
    gap: 935px;
    color: var(--white);
  }
  .property-item {
    position: absolute;
    top: 446px;
    left: 862px;
    border-radius: var(--br-11xl);
    background-color: var(--color-limegreen);
    width: 92px;
    height: 29px;
  }
  .save-25 {
    position: absolute;
    top: 452px;
    left: 874px;
    font-size: 14px;
    color: var(--white);
  }
}

// Figma to react css

.heading-2-wrapper {
  justify-content: center;
}

.frame-item-add {
  border-top: 2px solid var(--primary);
  box-sizing: border-box;
  // width: 100%;
  // padding: 0px;
  height: 2px;
}
.heading3-add {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-5xl);
}
.email-id {
  position: relative;
  cursor: pointer;
  color: var(--secondary, #333);
  text-align: center;
  font-family: var(--font-AlumniSans);
  font-size: 16px;
  font-style: normal;
  // font-weight: 600;
  line-height: normal;
}
.input-field2 {
  border-radius: var(--br-3xs);
  background-color: var(--white);
  border: 2px solid var(--gray);
  box-sizing: border-box;
  width: 197px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-xl);
  z-index: 0;
  border-radius: 10px;
  border: 2px solid var(--Gray, #ccc);
}
.mdiarrow-down-drop-icon {
  position: absolute;
  margin: 0 !important;
  top: 12px;
  left: 160px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.input-field2-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
  font-size: 16px;
}
.input-field2-wrapper,
.input-field21,
.input-field22 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
}
.input-field21 {
  border-radius: var(--br-3xs);
  background-color: var(--white);
  border: 2px solid var(--gray);
  box-sizing: border-box;
  width: 475px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  padding: var(--padding-mini) var(--padding-xl);
  border-radius: 10px;
  border: 2px solid var(--Gray, #ccc);
}
.input-field2-wrapper,
.input-field22 {
  align-items: flex-start;
}
.input-field22 {
  border-radius: var(--br-3xs);
  background-color: var(--white);
  border: 2px solid var(--gray);
  box-sizing: border-box;
  width: 255px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  padding: var(--padding-mini) var(--padding-xl);
  border-radius: 10px;
  border: 2px solid var(--Gray, #ccc);
}
.frame-parent,
.frame-wrapper-add {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent {
  align-self: stretch;
  flex-direction: row;
  //   gap: var(--gap-mid);
  gap: 14px;
}
.frame-wrapper-add {
  position: relative;
  // top: 1044px;
  // left: 20px;
  flex-direction: column;
  color: var(--black);
}
.input-field24,
.input-field25 {
  font-size: 18px;
  background-color: var(--white);
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 10px;
  border-radius: 5px;
  border: 1px solid #D1D5DB;
  &:focus-visible {
    border-color: var(--primary); // Change border color when focused and visible
    border-width: 2px; // Ensure border width stays consistent
    outline: none;
  }
}

.PhoneInputInput{
  &:focus-visible {
    // border-color: var(--primary); // Change border color when focused and visible
    // border-width: 2px; // Ensure border width stays consistent
    outline: none;
  }
}

.input-field25 {
  width: 100%;
}
.heading4-parent,
.input-field2-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-field2-group {
  flex-direction: row;
  gap: var(--gap-base);
  font-size: var(--font-size-base);
  color: var(--black);
  gap: 14px;
}
.heading4-parent {
  flex-direction: column;
  // gap: var(--gap-3xs);
}
.frame-div,
.input-field28 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.frame-div {
  align-items: flex-start;
  font-size: var(--font-size-base);
  color: var(--black);
}
.input-field28 {
  font-size: 14px !important;
  border-radius:5px !important;
  background-color: var(--white);
  border: 1px solid #E5E7EB !important;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  padding: 10px;
  z-index: 0;
  border-radius: 5px !important;
  margin-bottom: 28px;
  &::placeholder {
    color: rgba($secondary,0.5) !important;
    font-size: 14px; 
  }
  &:focus-visible {
    border-color: var(--primary); // Change border color when focused and visible
    border-width: 1px; // Ensure border width stays consistent
    outline: none; // Remove default browser outline
  }
}
.input-field20 {
  appearance: none; /* Remove the default dropdown styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2rem; /* Add padding to the right for the arrow */
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="6 9 12 15 18 9"/></svg>'); /* Custom arrow icon */
  background-repeat: no-repeat;
  background-position: calc(100% - 0.5rem) center; /* Adjust arrow position */
  background-size: 1rem;
  font-size: 14px;
  opacity: 0.9 !important;
  background-color: var(--white);
  border: 1px solid #E5E7EB;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  padding: 14px 16px;
  color:$secondary;
  border-radius: 5px;
  &::placeholder {
    color: rgba($secondary,0.5);
    font-size: 14px; 
  }
  &:focus-visible {
    border-color: rgba($orangePrimary,0.5); // Change border color when focused and visible
    border-width: 2px; // Ensure border width stays consistent
    outline: none; // Remove default browser outline
  }
}

.mdiarrow-down-drop-icon1 {
  position: absolute;
  margin: 0 !important;
  top: 15px;
  left: 366px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.input-field2-parent1,
.input-field29 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.input-field2-parent1 {
  width: 100%;
  align-items: flex-start;
  position: relative;
  gap: var(--gap-mid);
  font-size: var(--font-size-base);
  color: var(--black);
}
.input-field29 {
  font-size: 14px !important;
  color:$secondary;
  background-color: var(--white);
  border: 1px solid #E5E7EB;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  padding: 10px;
  z-index: 0;
  border-radius: 5px;
  &::placeholder {
    color: rgba($secondary,0.5);
    font-size: 14px; 
  }
  &:focus-visible {
    border-color: var(--primary); // Change border color when focused and visible
    border-width: 2px; // Ensure border width stays consistent
    outline: none; // Remove default browser outline
  }
}
.mdiarrow-down-drop-icon2 {
  position: absolute;
  margin: 0 !important;
  top: 12px;
  left: 55px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.input-field210 {
  font-size: 14px;
  border-radius: 5px;
  background-color: var(--white);
  border: 1px solid #E5E7EB;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  max-height: 44px;
  &::placeholder {
    color: rgba($secondary,0.5);
    font-size: 14px; 
  }
  &:focus-visible {
    border-color: var(--primary); // Change border color when focused and visible
    border-width: 1px; // Ensure border width stays consistent
    outline: none; // Remove default browser outline
  }
}
.mdiarrow-down-drop-icon3,
.mdiarrow-down-drop-icon4 {
  position: absolute;
  margin: 0 !important;
  top: 12px;
  left: 65px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.mdiarrow-down-drop-icon4 {
  left: 366px;
}
.input-field2-parent5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
}
.heading410 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-lg);
  color: var(--secondary);
}
.frame-inner {
  position: relative;
  border-radius: 50%;
  background-color: var(--white);
  border: 2px solid var(--gray);
  box-sizing: border-box;
  width: 30px;
  height: 30px;
}
.ellipse-group,
.ellipse-parent2 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  // gap: var(--gap-3xs);
}
.ellipse-group {
  display: none;
}
.ellipse-parent2 {
  display: flex;
  input {
    position: relative;
    border-radius: 50%;
    background-color: var(--white);
    border: 2px solid var(--gray);
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.frame-child3 {
  position: relative;
  border-radius: 50%;
  background-color: var(--white);
  border: 2px solid var(--primary);
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  z-index: 0;
}
.radio-select {
  position: relative;
  font-weight: 600;
  z-index: 1;
}
.frame-child4 {
  position: absolute;
  margin: 0 !important;
  top: 7px;
  left: 7px;
  border-radius: 50%;
  background-color: var(--primary);
  width: 16px;
  height: 16px;
  z-index: 2;
}
.ellipse-parent3,
.radio {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.ellipse-parent3 {
  align-items: center;
  position: relative;
  // gap: var(--gap-3xs);
  // gap: 7px;
  input {
    position: relative;
    border-radius: 50%;
    background-color: var(--white);
    border: 2px solid var(--primary);
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    z-index: 0;
  }
}
.radio {
  align-items: flex-start;
  gap: var(--gap-xl);
  gap: 5px;
  margin-top: 10px;
}
.adjust-how-creative {
  text-align: left;
  width: 100%;
  font-size: 10px;
  margin-top: 8px;
}
.description {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}
.description,
.heading4-parent8,
.input-field217 {
  display: flex;
  justify-content: flex-start;
}
.heading4-parent8 {
  // width: 400px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-3xs);
  margin-top: 14px;
}
.input-field217 {
  border-radius: var(--br-3xs);
  background-color: var(--white);
  border: 2px solid var(--gray);
  box-sizing: border-box;
  width: 399px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-mini) var(--padding-xl);
  z-index: 0;
  border-radius: 10px;
  border: 2px solid var(--Gray, #ccc);
}
.mdiarrow-down-drop-icon7 {
  position: absolute;
  margin: 0 !important;
  top: 12px;
  left: 365px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.heading4-parent9 {
  flex-direction: column;
  gap: var(--gap-3xs);
  font-size: var(--font-size-lg);
  color: var(--primary);
}
.frame-container,
.frame-group,
.heading4-parent9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-container {
  width: 1232px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-base);
  gap: 14px;
}
.frame-group-add {
  padding: var(--padding-3xs);
  position: relative;
  // top: 20px;
  // left: 20px;
  flex-direction: column;
  gap: var(--gap-xl);
  font-size: var(--font-size-lg);
}
.input-field218 {
  flex: 1;
  height: 230px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px var(--padding-11xl) var(--padding-11xl) var(--padding-xl);
  box-sizing: border-box;
  z-index: 0;
  border-radius: 5px;
  // box-shadow: 0px 0px 0px 3px #FFC3ADBF;
  // border:1px dashed rgba(108, 108, 108, 0.5);
}
.upload-1-icon {
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 40px);
  left: calc(50% - 26px);
  width: 53px;
  height: 53px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.input-field2-parent9 {
  display: flex;
  position: relative;
  height: 230px;
  border:1px dashed rgba(108, 108, 108, 0.5);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  // width: 400px;
  // flex-direction: row;
  // align-items: flex-start;
  // justify-content: flex-start;
  // gap: var(--gap-mid);

  input {
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: absolute;
    z-index: 9;
  }

  .cover-image {
    height: 370px;
    max-height: 100%;
    width: 100%;
    // object-fit: cover;
  }
}
.input-field219 {
  position: relative;
  flex: 1;
  height: 100%;
  overflow: hidden;
  display: flex;
  aspect-ratio: 1/1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 70px var(--padding-11xl) var(--padding-11xl) var(--padding-xl);
  box-sizing: border-box;
  z-index: 0;
  border-radius: 5px;
  border:1px dashed rgba(108, 108, 108, 0.5);
}
.upload-1-icon1 {
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.input-field2-parent10,
.input-field220 {
  border-radius: var(--br-3xs);
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.input-field2-parent10 {
  width: 100%;
  height: 80px;
  align-items: center;
  position: relative;
  gap: var(--gap-mid);

  input {
    opacity: 0;
    width: 100%;
    cursor: pointer;
    height: 80px;
    position: absolute;
    z-index: 9;
  }
  .propertyImage {
    // object-fit: cover;
    max-width: 100%;
    height: 80px;
    aspect-ratio: 16 / 9;
  }
}
.input-field220 {
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  padding: 14px 16px; 
  z-index: 0;
  border-radius: 5px;
  border: 1px solid #E5E7EB;
  height: 44px;
  font-size: 14px;
  font-family:'Roboto';
  color: #6C6C6C;
}
.mdiarrow-down-drop-icon8 {
  position: absolute;
  margin: 0 !important;
  top: 12px;
  left: 326.5px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.input-field221 {
  border-radius: 5px;
  background-color: var(--white);
  border: 1px solid #E5E7EB;;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  max-height: 44px;
  &::placeholder {
    color: rgba($secondary,0.5);
    font-size: 14px; 
  }
}
.frame-wrapper5,
.input-field222 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-field222 {
  border-radius: var(--br-3xs);
  background-color: var(--white);
  border: 2px solid var(--gray);
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  // flex-direction: row;
  align-items: center;
  padding: var(--padding-mini) var(--padding-xl);
  z-index: 0;
  border-radius: 10px;
  border: 2px solid var(--Gray, #ccc);
}
.mdiarrow-down-drop-icon9 {
  position: absolute;
  margin: 0 !important;
  top: 12px;
  left: 126.5px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.frame-parent6,
.input-field2-parent12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-field2-parent12 {
  position: relative;
  gap: var(--gap-mid);
}
.frame-parent6 {
  gap: var(--gap-3xs);
  font-size: var(--font-size-base);
  color: var(--black);
}
.frame-parent4,
.frame-parent5-add {
  display: flex;
  flex-direction: column;
  // gap: var(--gap-3xs);
}
.frame-parent5-add {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--secondary);
}
.frame-parent4 {
  // border-radius: var(--br-xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  // border-radius: 10px;
  // border: 2px solid var(--Accent, #f2f2f2);
  // padding: 15px;
  // background-color: var(--white);
}
.email-id23 {
  position: relative;
  font-weight: 600;
  // display: none;
}
.input-field223 {
  flex: 1;
  border-radius: var(--br-xl);
  background: var(--color-gainsboro);
  height: 400px;
  overflow: hidden;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: center;
  color: #ffffff;
  padding: 100px var(--padding-11xl) var(--padding-11xl) var(--padding-xl);
  box-sizing: border-box;
  z-index: 1;
  border-radius: 10px;
  // border: 2px solid var(--Gray, #ccc);
}
.vector-icon-add {
  position: absolute;
  margin: 0 !important;
  top: 130px;
  left: calc(50% - 53px);
  width: 107px;
  height: 107px;
  z-index: 1;
}
.input-field2-parent13 {
  // width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-mid);
  border: none;
  background: none;
}
.frame-parent3 {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: var(--gap-base);
  text-align: center;
  color: var(--white);
  gap: 14px;
}
.line-parent-add {
  border-radius: 20px;
  border: 2px solid var(--Gray, #ccc);
  background: var(--White, #fff);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  color: var(--secondary);
  border-radius: 20px;
  // padding: var(--padding-7xl);
}
.login {
  position: relative;
  letter-spacing: 0.05em;
  font-weight: 600;
  border: none;
  background: none;
}
.vector-icon1 {
  position: relative;
  width: 19px;
  height: 12px;
  display: none;
}
.login-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.button-icon-property,
.button-icon1-property {
  // position: absolute;
  // top: 1410px;
  // left: 84px;
  border-radius: var(--br-11xl);
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-7xl);
  border: 0;
}
.button-icon1-property {
  left: 304px;
}
.add-property-standard-plan {
  position: relative;
  background-color: var(--white);
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-base);
  color: var(--white);
  flex-direction: column;
  font-family: var(--font-AlumniSans);
}
.video-error {
  color: var(--white);
  font-size: 20px;
  button {
    border: none;
    background: none;
    color: var(--white);
    padding: 0;
    font-weight: 700;
  }
}

.responsive-video {
  width: -webkit-fill-available;
}

.dropdown-item.active,
.dropdown-item:active {
  background: none;
}

.delete-image-button {
  // background: none;
  border: none;
  color: black;
  background-color: var(--white);
  font-size: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 18px;
  height: 15px;
  cursor: pointer;
  z-index: 10;
  font-size: 10px;
  font-weight: 900;
}

.image-container {
  position: relative;
  display: inline-block;
}
.input-field {
  .form-control {
    border: 0;
    padding: 0;
  }
}

// .css-13cymwt-control {
//   border: none !important;
//   min-height: auto !important;
// }

// .css-1hb7zxy-IndicatorsContainer {
//   display: none !important;
// }

// .css-t3ipsp-control:hover {
//   border-color: white !important;
// }

.newCustomInput {
  border-radius: var(--br-3xs);
  background-color: var(--white);
  border: 2px solid var(--gray);
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-xl);
  border-radius: 10px;
  max-height: 40px;
  border: 2px solid var(--Gray, #ccc);
}
.customAudioInput {
  width: 60px;
  opacity: 0;
  cursor: pointer;
  height: 60px;
  position: absolute;
}

.Image_counter {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 900;
  top: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  background-color: red; /* Change color as needed */
  border-radius: 50%;
  box-shadow: 2px 0px 10px rgb(0 0 0 / 80%);
}
.videoDetails_Modal{
  position: relative;
}
.videoDetails_Image_cancel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 900;
  top: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  background-color: rgb(204, 204, 204); /* Change color as needed */
  border-radius: 50%;
  box-shadow: 2px 0px 10px rgba(146, 146, 146, 0.8);
  border: 1px solid black;
  cursor: pointer;
}

.Property-round-checkbox {
  appearance: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  background-color: var(--Accent, #f2f2f2);
  border: 1px solid var(--primary);
}

/* Styling for checked state */
.Property-round-checkbox:checked {
  background-color: var(--primary);
}

/* Creating a custom check mark */
.Property-round-checkbox:checked:after {
  content: '\2714'; /* Unicode character for check mark */
  position: absolute;
  top: 0px;
  left: 5px;
  color: white;
  font-size: 25px;
}
.Property-round-checkbox:after {
  // content: '\2714'; /* Unicode character for check mark */
  position: absolute;
  top: 0px;
  left: 6px;
  // color: var(--primary);
  font-size: 25px;
}
