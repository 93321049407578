@import 'variables.scss';

.landing-page-child,
.logo-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 1750px;
    height: 1159px;
    &.logo-child {
        border-radius: 50%;
        background-color: var(--primary);
        width: 24px;
        height: 24px;
    }
}
.logo-here {
    position: absolute;
    top: 2px;
    left: 34px;
    font-weight: 500;
}
.logo {
    width: 250px;
    height: 50px;
}
.home,
.logo {
    position: relative;
    cursor: pointer;
}
.navlink {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs);
    text-decoration: none;
    color: var(--primary);
    font-size: 24px;
    font-weight: 600;
    &:hover {
        color: var(--secondaryRed);
    }
}
.navlink-active {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs);
    text-decoration: none;
    color: var(--secondaryRed);
    font-size: 24px;
    font-weight: 600;
}
.login {
    position: relative;
    letter-spacing: 0.05em;
    font-weight: 600;
    color: var(--color-white);
}
.vector-icon {
    position: relative;
    width: 19px;
    height: 12px;
    display: none;
}
.button-icon,
.login-parent {
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    &.login-parent {
        flex-direction: row;
        align-items: center;
        gap: var(--gap-6xs);
    }
    &.button-icon {
        border-radius: var(--br-11xl);
        background-color: var(--primary);
        overflow: hidden;
        flex-direction: column;
        align-items: flex-start;
        padding: var(--padding-mini) var(--padding-7xl);
        text-align: center;
        color: var(--white);
    }
}
.write-custom {
    position: relative;
    font-weight: 500;
}
.write-custom-wrapper {
    font-family: var(--font-AlumniSans);
}
.descriptions-in-seconds-wrapper,
.write-custom-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.save-time-and {
    flex: 1;
    position: relative;
}
.subheading {
    // width: 616px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px 0;
    box-sizing: border-box;
    font-size: 20px;
}
.vector-icon1 {
    position: relative;
    width: 19px;
    height: 12px;
}
.button-icon1 {
    border-radius: var(--br-11xl);
    background-color: var(--primary);
    overflow: hidden;
    padding: var(--padding-mini) var(--padding-7xl);
    text-align: center;
    font-size: var(--font-size-base);
    color: var(--white);
}
.banner1,
.button-icon1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.bannerimg-1-icon {
    // position: relative;
    width: 600px;
    height: 589px;
    object-fit: cover;
}
.banner {
    background-image: url(../images/banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    // max-width: 1274px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 52px;
    @media (max-width: 468px) {
        margin-top: 100px;
    }
}
.heading-2 {
    // position: relative;
    font-weight: 600;
    font-family: var(--font-roboto);
    // color: var(--black);
}
.heading-2-wrapper {
    padding: var(--padding-3xs);
}
.description-home,
.heading-2-wrapper,
.heading3-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.description-home {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--font-size-base);
}
.heading3-parent {
    flex-direction: column;
    gap: 10px;
    color: #000;
    font-weight: 400;
    margin-bottom: 20px;
}
.description1,
.frame-group,
.heading3-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.description1 {
    flex-direction: row;
    flex-wrap: wrap;
    font-size: var(--font-size-base);
}
.frame-group {
    width: auto;
    flex-direction: column;
    gap: var(--gap-xl);
}
.frame-child-home {
    position: relative;
    width: 156px;
    height: 144px;
    object-fit: cover;
}
.frame-parent1,
.rectangle-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.rectangle-parent {
    // width: 333px;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: var(--gap-xl);
}
.frame-parent1 {
    border-radius: 20px;
    background-color: var(--color-white);
    overflow: hidden;
    padding: 20px;
    gap: 48px;
    text-align: left;
    font-size: var(--font-size-5xl);
    color: var(--black);
}
.sliderBG {
    border-radius: 20px;
    background-color: var(--color-white);
    overflow: hidden;
    padding: 20px;
    font-size: var(--font-size-5xl);
    color: var(--black);
    min-height: 500px;
}
.ellipse-div,
.frame-child1 {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    &.frame-child1 {
        background-color: var(--color-gray);
    }
}
.ellipse-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 13px;
}
.instance-parent {
    position: relative;
    background-color: var(--primary);
    width: 100%;
    // height: 665px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-11xl);
    color: var(--white);
    display: flex;
    // align-items: center;
    flex-direction: column;
    padding-bottom: 100px;
}
.landing-page {
    // text-align: left;
    // font-size: var(--font-size-base);
    // color: var(--primary);
    // font-family: var(--font-AlumniSans);
    // left: calc(50% - 636px);
    // overflow: hidden;
}
// How it works start
.heading-2 {
    flex: 1;
    font-weight: 600;
}
.heading-2-wrapper {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs);
    box-sizing: border-box;
}
.frame-child-round {
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(239,52,42,1) 0%, rgba(250,166,59,1) 100%);
    width: 72px;
    height: 72px;
    z-index: 0;
}
.frame-item {
    margin: 0 !important;
    height: 45.58%;
    width: 8.57%;
    top: 27.78%;
    right: 45.71%;
    bottom: 26.64%;
    left: 45.71%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    z-index: 1;
}
.ellipse-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: var(--gap-3xs);
}
.add-property-details {
    margin: 0;
    padding-left: var(--padding-13xl);
}
.frame-container1,
.heading3 {
    align-self: stretch;
    display: flex;
}
.heading3 {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-xl) 0;
}
.frame-container1 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-3xs);
}
.add-your-listings {
    flex: 1;
    position: relative;
}
.desc {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    font-size: var(--font-size-base);
    font-family: var(--font-AlumniSans);
}
.desc,
.frame-group,
.frame-wrapper1 {
    display: flex;
    justify-content: flex-start;
}
.frame-group {
    flex-direction: column;
    align-items: flex-start;
}
.frame-wrapper1 {
    border-radius: var(--br-xl);
    background-color: var(--white);
    overflow: hidden;
    flex-direction: row;
    align-items: center;
}
.layer-7-icon,
.vector-icon {
    margin: 0 !important;
    z-index: 1;
}
.layer-7-icon {
    height: 35.65%;
    width: 8.57%;
    top: 31.94%;
    right: 45.71%;
    bottom: 32.4%;
    left: 45.71%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}
.vector-icon {
    top: 21px;
    left: 160px;
    width: 30px;
    height: 30px;
}
.frame-parent1 {
    top: 154px;
    left: 84px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-91xl);
    font-size: var(--font-inherit);
    color: var(--black);
    font-family: var(--font-AlumniSans);
}
.how-it-works {
    background-color: var(--white);
    width: 100%;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-11xl);
    color: var(--primary);
    font-family: var(--font-AlumniSans);
    padding-bottom: 50px;
}
// How it works end

// why use start
.heading-22 {
    position: relative;
    font-weight: 600;
}
.heading-2-container,
.instance-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.heading-2-container {
    width: 1440px;
    justify-content: center;
    padding: var(--padding-3xs) 0;
    box-sizing: border-box;
}
.instance-wrapper {
    justify-content: flex-start;
}
.vector-icon1 {
    position: relative;
    width: 40px;
    height: 40px;
}
.heading3-wrapper,
.heading34,
.vector-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.heading3-wrapper,
.heading34 {
    align-items: center;
}
.heading3-wrapper {
    flex-direction: column;
}
.agents-spend-30mins-1hr {
    position: relative;
    display: inline-block;
    // width: 344px;
    // flex-shrink: 0;
}
.description3 {
    // width: 414px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-base);
}
.frame-parent7 {
    // width: 413px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
}
.frame-wrapper2 {
    border-radius: 20px;
    background-color: var(--white);
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px;
    box-sizing: border-box;
    height: 100%;
}
.group-icon {
    position: relative;
    width: 33.86px;
    height: 40px;
}
.group-wrapper {
    align-self: stretch;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.frame-parent5,
.frame-parent6 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gap-31xl);
}
.frame-parent6 {
    flex-direction: row;
    flex-wrap: wrap;
    font-size: var(--font-size-5xl);
    color: var(--black);
}
.frame-parent5 {
    margin-top: 50px;
    margin-bottom: 50px;
    flex-direction: column;
}
.why-use {
    position: relative;
    background-color: "#F2F2F2";
    width: 100%;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-11xl);
    color: var(--primary);
    font-family: var(--font-AlumniSans);
    background-color: #f2f2f2;
}
// why use end

// footer start
footer {
    margin-top: 100px;
    background-color: var(--primary);
    padding: 30px 0;
    border-top: 1px solid #33333399;
    .footerLink {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
            margin-bottom: 10px;
        }
    }
}
.iconBG {
    width: 24px;
    height: 24px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}
// footer end

// FAQ page start
.commanBanner {
    background-color: var(--primary);
    margin-top: 75px;
    .commanBanner_heading {
        min-height: 90px;
        @media (max-width: 769px) {
            min-height: 100px;
        }
    }
}
.FAQSection {
    // background-color: $white;
    background-color: #faf7f3;

    .accordion {
        // --bs-accordion-bg: var(--Secondary, #3D0814) !important;
        // --bs-accordion-active-bg: var(--Secondary, #3D0814) !important;
        // border-radius: 10px 10px 0px 0px;
        --bs-accordion-bg: var(--primary) !important;
        --bs-accordion-active-bg: var(--primary) !important;
        --bs-accordion-btn-icon: ;
    }

    .accordion-item {
        border-radius: 10px;
        // border: 1px solid var(--primary);
        background: var(--secondary);
        margin-top: 15px;
        border-radius: 10px;
        color: #fff;
        font-family: var(--font-AlumniSans);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .accordion-body {
        background-color: #fff;
        color: var(--secondary);
        font-family: var(--font-AlumniSans);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 0 0 8px 8px;
    }

    .accordion-button {
        color: #fff;
        border-radius: 8px;
        // text-align: center;
        font-family: var(--font-AlumniSans);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        &:hover {
            background-color: var(--primary);
        }
        &:not(.collapsed) {
            background-color: var(--primary);
        }
        &:focus {
            background-color: var(--primary);
        }
    }

    .accordion-button::after {
        content: url("../images/arrow.svg");
        background-image: none;
    }

    .accordion-header {
        border-radius: 10px 10px 0px 0px;
        border-radius: 10px;
    }

    .title {
        color: #fff;
        text-align: center;
        font-family: var(--font-AlumniSans);
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .faqTitle {
        background: var(--primary);
        color: #fff;
        text-align: center;
        font-family: var(--font-AlumniSans);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        &:hover {
            background: var(--primary);
            cursor: pointer;
        }
    }
}
// FAQ page end

// Contact us page start
.icContact {
    width: 62px;
    height: 62px;
    border: solid 2px var(--primary);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inputCustom {
    border-radius: 5px !important;
    border: 1px solid #D1D5DB !important;
    padding: 7px 10px !important;
    background: rgba(255, 255, 255, 0.2);
    padding: 15px;
    color: var(--secondary);
    font-family: var(--font-AlumniSans);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.9px;
}
// Contact us page end
.notificationNum {
    position: absolute;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // width: 20px;
    // height: 20px;
    // background-color: #fc0101;
    border-radius: 100%;
    left: 13px;
    top: 8px;
    color: $orangePrimary;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.navNotifications {
    .heading {
        color: var(--secondary);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 5px 15px;
    }
    .title {
        color: var(--Black, #000);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .date {
        color: #565656;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.notifications-container {
    height: 200px;
    overflow-y: scroll;
    min-width: 380px;
    @media (max-width: 468px) {
        min-width: 100%;
    }
}

.pptPlan {
    padding: 40px 80px 40px;
}
.upgradePlan {
    // background: rgb(27,86,82,0.6);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 88;
    flex-direction: column;
}
.carousel-indicators {
    margin-bottom: -2rem;
}
.noProperty {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-shrink: 0;
    border-radius: 30px;
    background: rgba(9, 50, 84, 0.1);
    padding: 50px 15px;
}
.font20 {
    font-size: 20px;
}
