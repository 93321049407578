// ===== font size ====
.fs10 {
  font-size: 10px;
}

.fs12 {
  font-size: 12px;
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
  @media (max-width: 998px) {
    font-size: 14px;
  }
  @media (max-width: 769px) {
    font-size: 12px;
  }
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
  font-weight: 600;
}

.fs22 {
  font-size: 22px;
}

.fs24 {
  font-size: 24px;
  font-weight: 600;
  @media (max-width: 998px) {
    font-size: 20px;
  }
  @media (max-width: 769px) {
    font-size: 18px;
  }
}

.fs26 {
  font-size: 26px;
}

.fs28 {
  font-size: 28px;
}

.fs30 {
  font-size: 30px;
}

.fs32 {
  font-size: 32px;
}

.fs34 {
  font-size: 34px;
}

.fs36 {
  font-size: 36px;
}

.fs38 {
  font-size: 38px;
}

.fs40 {
  font-size: 40px;
}

.fs42 {
  font-size: 42px;
}

.fs44 {
  font-size: 44px;
}

.fs46 {
  font-size: 46px;
}

.fs48 {
  font-size: 48px;
}

.fs50 {
  font-size: 50px;
}

.fs52 {
  font-size: 52px;
}

.fs54 {
  font-size: 54px;
}

.fs56 {
  font-size: 56px;
}

.fs58 {
  font-size: 58px;
}

.fs60 {
  font-size: 60px;
}

.fs62 {
  font-size: 62px;
}

.fs64 {
  font-size: 64px;
}

.fs66 {
  font-size: 66px;
}

.fs68 {
  font-size: 68px;
}

.fs70 {
  font-size: 70px;
}

.fs72 {
  font-size: 72px;
}

.fs74 {
  font-size: 74px;
}

.fs76 {
  font-size: 76px;
}

.fs78 {
  font-size: 78px;
}

.fs80 {
  font-size: 80px;
}

.fs82 {
  font-size: 82px;
}

.fs84 {
  font-size: 84px;
}

.fs86 {
  font-size: 86px;
}

.fs88 {
  font-size: 88px;
}

.fs90 {
  font-size: 90px;
}

.fs92 {
  font-size: 92px;
}

.fs94 {
  font-size: 94px;
}

.fs96 {
  font-size: 96px;
}

.fs98 {
  font-size: 98px;
}

.fs100 {
  font-size: 100px;
}

.fs102 {
  font-size: 102px;
}

.fs104 {
  font-size: 104px;
}

.fs106 {
  font-size: 106px;
}

.fs108 {
  font-size: 108px;
}

.fs110 {
  font-size: 110px;
}

.fs112 {
  font-size: 112px;
}

.fs114 {
  font-size: 114px;
}

.fs116 {
  font-size: 116px;
}

.fs118 {
  font-size: 118px;
}

.fs120 {
  font-size: 120px;
}

.fs122 {
  font-size: 122px;
}

.fs124 {
  font-size: 124px;
}

.fs126 {
  font-size: 126px;
}

.fs128 {
  font-size: 128px;
}

.fs130 {
  font-size: 130px;
}

.fs132 {
  font-size: 132px;
}

.fs134 {
  font-size: 134px;
}

.fs136 {
  font-size: 136px;
}

.fs138 {
  font-size: 138px;
}

.fs140 {
  font-size: 140px;
}

.fs142 {
  font-size: 142px;
}

.fs144 {
  font-size: 144px;
}

.fs146 {
  font-size: 146px;
}

.fs148 {
  font-size: 148px;
}

.fs150 {
  font-size: 150px;
}

.fs152 {
  font-size: 152px;
}

.fs154 {
  font-size: 154px;
}

.fs156 {
  font-size: 156px;
}

.fs158 {
  font-size: 158px;
}

.fs160 {
  font-size: 160px;
}

.fs162 {
  font-size: 162px;
}

.fs164 {
  font-size: 164px;
}

.fs166 {
  font-size: 166px;
}

.fs168 {
  font-size: 168px;
}

.fs170 {
  font-size: 170px;
}

.fs172 {
  font-size: 172px;
}

.fs174 {
  font-size: 174px;
}

.fs176 {
  font-size: 176px;
}

.fs178 {
  font-size: 178px;
}

.fs180 {
  font-size: 180px;
}

.fs182 {
  font-size: 182px;
}

.fs184 {
  font-size: 184px;
}

.fs186 {
  font-size: 186px;
}

.fs188 {
  font-size: 188px;
}

.fs190 {
  font-size: 190px;
}

.fs192 {
  font-size: 192px;
}

.fs194 {
  font-size: 194px;
}

.fs196 {
  font-size: 196px;
}

.fs198 {
  font-size: 198px;
}

.fs200 {
  font-size: 200px;
}

// ===== font weight ====
.fw100 {
  font-weight: 100;
}

.fw200 {
  font-weight: 200;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

// ===== font style ====
.fstNormal {
  font-style: normal;
}

// ===== font family ====
.ffInter {
  font-family: var(--font-AlumniSans);
}

.ffManrope {
  font-family: var(--font-AlumniSans);
}

// ===== line height ====
.lh0 {
  line-height: 0;
}

.lh2 {
  line-height: 2px;
}

.lh4 {
  line-height: 4px;
}

.lh6 {
  line-height: 6px;
}

.lh8 {
  line-height: 8px;
}

.lh10 {
  line-height: 10px;
}

.lh12 {
  line-height: 12px;
}

.lh14 {
  line-height: 14px;
}

.lh16 {
  line-height: 16px;
}

.lh18 {
  line-height: 18px;
}

.lh20 {
  line-height: 20px;
}

.lh22 {
  line-height: 22px;
}

.lh24 {
  line-height: 24px;
}

.lh26 {
  line-height: 26px;
}

.lh28 {
  line-height: 28px;
}

.lh30 {
  line-height: 30px;
}

.lh32 {
  line-height: 32px;
}

.lh34 {
  line-height: 34px;
}

.lh36 {
  line-height: 36px;
}

.lh38 {
  line-height: 38px;
}

.lh40 {
  line-height: 40px;
}

.lh42 {
  line-height: 42px;
}

.lh44 {
  line-height: 44px;
}

.lh46 {
  line-height: 46px;
}

.lh48 {
  line-height: 48px;
}

.lh50 {
  line-height: 50px;
}

.lh52 {
  line-height: 52px;
}

.lh54 {
  line-height: 54px;
}

.lh56 {
  line-height: 56px;
}

.lh58 {
  line-height: 58px;
}

.lh60 {
  line-height: 60px;
}

.lh62 {
  line-height: 62px;
}

.lh64 {
  line-height: 64px;
}

.lh66 {
  line-height: 66px;
}

.lh68 {
  line-height: 68px;
}

.lh70 {
  line-height: 70px;
}

.lh72 {
  line-height: 72px;
}

.lh74 {
  line-height: 74px;
}

.lh76 {
  line-height: 76px;
}

.lh78 {
  line-height: 78px;
}

.lh80 {
  line-height: 80px;
}

.lh82 {
  line-height: 82px;
}

.lh84 {
  line-height: 84px;
}

.lh86 {
  line-height: 86px;
}

.lh88 {
  line-height: 88px;
}

.lh90 {
  line-height: 90px;
}

.lh92 {
  line-height: 92px;
}

.lh94 {
  line-height: 94px;
}

.lh96 {
  line-height: 96px;
}

.lh98 {
  line-height: 98px;
}

.lh100 {
  line-height: 100px;
}

.lh102 {
  line-height: 102px;
}

.lh104 {
  line-height: 104px;
}

.lh106 {
  line-height: 106px;
}

.lh108 {
  line-height: 108px;
}

.lh110 {
  line-height: 110px;
}

.lh112 {
  line-height: 112px;
}

.lh114 {
  line-height: 114px;
}

.lh116 {
  line-height: 116px;
}

.lh118 {
  line-height: 118px;
}

.lh120 {
  line-height: 120px;
}

.lh122 {
  line-height: 122px;
}

.lh124 {
  line-height: 124px;
}

.lh126 {
  line-height: 126px;
}

.lh128 {
  line-height: 128px;
}

.lh130 {
  line-height: 130px;
}

.lh132 {
  line-height: 132px;
}

.lh134 {
  line-height: 134px;
}

.lh136 {
  line-height: 136px;
}

.lh138 {
  line-height: 138px;
}

.lh140 {
  line-height: 140px;
}

.lh142 {
  line-height: 142px;
}

.lh144 {
  line-height: 144px;
}

.lh146 {
  line-height: 146px;
}

.lh148 {
  line-height: 148px;
}

.lh150 {
  line-height: 150px;
}

.lh152 {
  line-height: 152px;
}

.lh154 {
  line-height: 154px;
}

.lh156 {
  line-height: 156px;
}

.lh158 {
  line-height: 158px;
}

.lh160 {
  line-height: 160px;
}

.lh162 {
  line-height: 162px;
}

.lh164 {
  line-height: 164px;
}

.lh166 {
  line-height: 166px;
}

.lh168 {
  line-height: 168px;
}

.lh170 {
  line-height: 170px;
}

.lh172 {
  line-height: 172px;
}

.lh174 {
  line-height: 174px;
}

.lh176 {
  line-height: 176px;
}

.lh178 {
  line-height: 178px;
}

.lh180 {
  line-height: 180px;
}

.lh182 {
  line-height: 182px;
}

.lh184 {
  line-height: 184px;
}

.lh186 {
  line-height: 186px;
}

.lh188 {
  line-height: 188px;
}

.lh190 {
  line-height: 190px;
}

.lh192 {
  line-height: 192px;
}

.lh194 {
  line-height: 194px;
}

.lh196 {
  line-height: 196px;
}

.lh198 {
  line-height: 198px;
}

.lh200 {
  line-height: 200px;
}

// ===== latter spacing ====
.lsp0 {
  letter-spacing: 0px;
}

.lsp1 {
  letter-spacing: 1px;
}

.lsp2 {
  letter-spacing: 2px;
}

.lsp3 {
  letter-spacing: 3px;
}

.lsp4 {
  letter-spacing: 4px;
}

.lsp5 {
  letter-spacing: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.buttonGray:focus {
  background: #7f858a;
  color: #ffffff;
  outline: 3px white;
  box-shadow: 0 0 0 3px rgba(83, 87, 90, 0.5);
}

.buttonBlue {
  border-radius: 30px;
  background: #E03C31;
}

.inputField {
  border-radius: 30px;
  border: 1px solid var(--Gray, #ccc);
  background: var(--White, #fff);
}

.my50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

// old fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");

// New fonts
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,100..900;1,100..900&display=swap');


body {
  margin: 0;
  line-height: normal;
  // font-family: var();
  font-family: "Roboto";
  font-size: 18px;
  color: var(--black);
}
:root {
  /* fonts */
  // --font-raleway:'Roboto';
  // --font-inter: Inter;

  // : 'Roboto', sans-serif;
  // --font-lato: 'Lato', sans-serif;
  --font-AlumniSans: "Roboto";

  /* font sizes */
  --font-size-base: 16px;
  --font-size-5xl: 24px;

  /* Colors */
  --primary: #393E41;
  --secondaryRed: #FF6B35;
  --color-white: #fff;
  --black: #343942;
  --gray: #cccccc;
  --color-gainsboro: #F2F2F2;
  --white: #fff;
  --color-limegreen: #06c038;
  --secondary: #343942;
  --complementary: #4A90E2;

  /* Paddings */
  --padding-mini: 15px;
  --padding-20xl: 39px;
  --padding-sm: 14px;
  --padding-xl: 20px;
  --padding-3xs: 10px;
  --padding-7xl: 26px;

  /* Border radiuses */
  --br-11xl: 30px;
  --gap-3xl: 22px;
  --font-size-11xl: 30px;

  /* Colors */
  --white: #fff;
  --color-darkslategray-100: rgba(9, 50, 84, 0.1);

  /* Gaps */
  --gap-xs: 12px;
  --gap-3xs: 10px;
  --gap-6xs: 7px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-3xs: 10px;
  --padding-mini: 15px;
  --padding-7xl: 26px;

  /* Border radiuses */
  --br-11xl: 30px;
}

.modalCustomNew {
  z-index: 9999;
  .modal-content {
    background-color: #fff;
    border-radius: 0;
    // min-height: 70vh;

    .modal-header {
      padding: 10px 10px;
      font-family: --font-AlumniSans;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;

      .btn-close {
        width: 36px;
        height: 36px;
        border-radius: 0;
        color: #c4cfda;
        // background-color: var(--gray);
        padding: 0;
        margin: 0;
        margin-left: auto;
      }
    }
  }
}

.navbar-bg {
  background-color: transparent; /* Initially transparent background */
  z-index: 1000; /* Ensure navbar stays on top of other content */
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.scrolled {
  background: rgba(240,240,240, .7) !important;
  backdrop-filter: blur(20px);
}
.react-tel-input {
  .special-label {
    display: none;
  }
}
.fontRoboto {
  font-family: var(--font-AlumniSans);
}
.fontLato {
  font-family: var(--font-AlumniSans);
}
.colorRed {
  color: var(--primary);
}
.colorWhite {
  color: var(--white);
}
.colorGray {
  color: var(--black);
}

// New css
.colorPrimary {
  color: var(--primary);
}
.font70 {
  font-size: 70px;
  line-height: 110%;
  color: var(--primary);
}
.font30 {
  font-size: 30px;
}
.font20 {
  font-size: 20px;
}
.fontLight {
  font-weight: 300;
}
.fontNormal {
  font-weight: 400;
}
.fontSemibold {
  font-weight: 600;
}
.letterSpacing {
  letter-spacing: 1px;
}
.navbar-toggler-icon{
  width: 20px !important;
  height: 20px !important;
}

@media (max-width: 992px) {
  .navbar-bg{
    background:rgba(240,240,240, .7);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
}
@media (max-width: 576px) {
  .btnGreen{
    font-size: 16px !important;
    padding: 12px 20px !important;
  }
}
@media (min-width: 320px) {
  .font70 {
    font-size: calc(2.5rem + ((1vw - 3.2px) * 1.875)) !important;
  }
  .font30 {
    font-size: calc(1.25rem + ((1vw - 3.2px) * 0.625)) !important;
  }
  .font20 ,.font20BlkReg{
    font-size: calc(1rem + ((1vw - 3.2px) * 0.25)) !important;
  }
  .font24White {
    font-size: calc(1.125rem + ((1vw - 3.2px) * 0.375)) !important;
  }
  .link20White {
    font-size: calc(1rem + ((1vw - 3.2px) * 0.25)) !important;
  }
  .font30Black {
    font-size: calc(1.375rem + ((1vw - 3.2px) * 0.5)) !important;
  }
  .font24Blue {
      font-size: calc(1.125rem + ((1vw - 3.2px) * 0.375)) !important;
  }
  .font40White {
    font-size: calc(1.75rem + ((1vw - 3.2px) * 0.75)) !important ;
  }
  .fs26 {
    font-size: calc(1.125rem + ((1vw - 3.2px) * 0.5)) !important ;
  }
  .description-tab .tab-content {
    font-size: calc(1rem + ((1vw - 3.2px) * 0.25)) !important ;
  }
  .home {
    font-size: calc(1.125rem + ((1vw - 3.2px) * 0.25)) !important ;
  }
  .input-field24, .input-field25 ,.input-field28,.input-field210,.input-field29 {
    font-size: calc(0.875rem + ((1vw - 3.2px) * 0.25)) !important ;
  }
  .fs50 {
    font-size: calc(1.75rem + ((1vw - 7.68px) * 1.9097)) !important;
  }
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* Aspect ratio 16:9 */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gap-20 {
  gap: 20px
}