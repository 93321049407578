@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-ThinItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-UltralightItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-UltralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Medium.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-HeavyItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-LightItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-LightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Bold.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-BlackItalic.woff2') format('woff2'),
        url('../fonts/SFProDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-SemiBold.woff2') format('woff2'),
        url('../fonts/Roboto-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  
}

